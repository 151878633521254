import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import CourseCard from "./CourseCard";
import InfiniteScroll from "react-infinite-scroll-component";
import SyncLoader from "react-spinners/SyncLoader";
import CoursesFilter from "../Course/CoursesFilter";
import { RiEqualizerLine } from "react-icons/ri";
import CourseGiftCard from "../GiftCard/CourseGiftCard";

function CourseList({cardKind="default", ignore=[], linkParams={},
  filter={show: false, school: 0, cat: 0, lang: ''}}) {
  const [state, setState] = useState({items: [], hasMore: true, offset: 0});
  const [ignoreIds,] = useState(new Set(ignore.map(objeto => objeto.id)));
  const [showFilter, setShowFilter] = useState(false);
  const [school, setSchool] = useState(filter.school);
  const [cat, setCat] = useState(filter.cat);
  const [lang, setLang] = useState(filter.lang);
  const limit = 48;

  const itemsError = (data) => {
    setState({items: state.items, hasMore: false, offset: state.offset});
  }

  const itemsSuccess = (data) => {
    if (data && data.length > 0) {
        var items = [...state.items];
        for (var i = 0; i < data.length; i++) {
          if (!ignoreIds.has(data[i].id))
            items.push(data[i]);
        }    
        setState({
            items: items,
            hasMore: data.length >= limit,
            offset: state.offset + data.length
        });
    } else itemsError(data);
  }

  const fetchMoreData = () => {
    getAPICall('courses/enum', {lang: lang, school: school, cat: cat, offset: state.offset, limit: limit},
      itemsSuccess, itemsError);
  }

  useEffect(() => {
    fetchMoreData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilter = (sc, ca, lng) => {
    if (sc) setSchool(sc);
    else sc = school;
    if (ca) setCat(ca);
    else ca = cat;
    if (lng) setLang(lng);
    else lng = lang;
    state.items.splice(0, state.items.length);
    state.offset = 0;
    state.hasMore = true;
    getAPICall('courses/enum', {lang: lng, school: sc, cat: ca, offset: 0, limit: limit},
        itemsSuccess, itemsError);
  }

  return <>
    {filter.show && <div className="pad12">
      <div style={{textAlign: "right"}}>
        <RiEqualizerLine className="blackTextHover" size="24px" onClick={()=>{setShowFilter(!showFilter)}}/>
      </div>
      <CoursesFilter show={showFilter} onFilter={onFilter}/>
    </div>}
    <div style={{display: "flex", flexWrap: "wrap", marginBottom: "16px"}} id="itemsScrollableDiv">
      <InfiniteScroll
        dataLength={state.items.length}
        next={fetchMoreData}
        hasMore={state.hasMore}
        loader={<p><SyncLoader size={18} color="#73694c"/></p>}
        style={{display: "flex", flexWrap: "wrap"}}
        scrollableTarget="itemsScrollableDiv"
        endMessage={null}>
        {state.items.map((data, index) => {
            return cardKind === "default" ?
            <CourseCard key={index} course={data} top={false}/> :
            <CourseGiftCard key={index} course={data} linkParams={linkParams}/>
      })}
      </InfiniteScroll>
    </div>
  </>
}

export default CourseList