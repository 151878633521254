import { Link } from "react-router-dom";
import Translate from "../AppContext/Translate";

function CourseGiftCard({course, linkParams}) {
    const addr = "/giftcourse/" + linkParams.design + "-" + course.id;
    const pic = "/courses/cover" + course.id + ".jpg";
    return <div className="pad16">
        <Link to={addr}>
            <div className='mainCard'>
                <figure style={{overflow: "hidden"}}><img alt="" src={pic} width="100%" /></figure>
                <div style={{padding: "0px 12px 0px 12px", color: "black"}}>
                    <p className="fkenyan bold large">{course.title}</p>
                    <p className="fmak top8 small">
                        <Translate>Курс від</Translate>
                        {' '}{course.school}
                    </p>
                </div>
            </div>
        </Link>
    </div>
}

export default CourseGiftCard