import Form from 'react-bootstrap/Form';
import Translate, { translate } from '../../AppContext/Translate';
import WriteHelper from '../../Components/WriteHelper';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import UploadProgress from '../../Components/UploadProgress';
import Button from 'react-bootstrap/Button';
import { postAPICall } from '../../Components/APICall';
import { useNavigate } from 'react-router-dom';
import { MediaPath } from '../../Components/MediaPath';
import RTEditor from '../../Components/RTEditor';
import rtEditorUploader from '../../Components/RTEditorUploader';
import SchoolCover from '../../School/SchoolCover';
import SchoolLogo from '../../School/SchoolLogo';
import SchoolMessageAppPendant from './SchoolMessageAppPendant';
import CabUploadLocalFile from '../CabUploadLocalPNG';
import SchoolTerms from '../../Terms/SchoolTerms';

function SchoolForm({school, setSchool}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [name, setName] = useState('');
    const [saveInfo, setSaveInfo] = useState(null);
    const [progress, setProgress] = useState(null);
    const [description, setDescription] = useState({text: '', files: []});
    const [agree, setAgree] = useState(school !== null);
    let navigate = useNavigate();

    useEffect(() => {
        setName(school !== null && school.length > 0 ? school[0].title : '');
        if (school !== null && school.length > 0) {
            (async () => {
                var path = new MediaPath(MediaPath.kind_publicSchool);
                path.add(school[0].id);
                path.addStr('desc');
                var msg = await WriteHelper.parseMessage(school[0].description ? school[0].description : '',
                    path, userId, token); 
                setDescription(msg);
            })();
        }
        setAgree(school !== null);
    }, [school, userId, token]);

    const changes = () => {
        setSaveInfo({text: <Translate>Є зміни, які очікують на збереження</Translate>, color: "lightblue"});
    }

    const onSchoolCreated = (data) => {
        if (data && 'id' in data) {
            if (data.id < 0) {
                setSaveInfo({text: <><Translate>Помилка</Translate>: <Translate>назва вже існує</Translate></>, 
                    color: "#ffdddd"});
            } else {
                navigate('/load/cabinet/school');
            }
        } else onSchoolCreateError(data);
    }

    const onSchoolCreateError = (data) => {
        setSaveInfo({text: <><Translate>Помилка</Translate>: <Translate>не вдалося зберегти зміни</Translate></>, 
            color: "#ffdddd"});
    }

    const createSchool = (evnt) => {
        evnt.preventDefault();
        if (!agree) {
            setSaveInfo({text: <><Translate>Помилка</Translate>: <Translate>необхідно прийняти умови договору</Translate></>, 
                color: "#ffdddd"});
        } else if (name.length < 3 || name.length > 64 || name.includes("'") || name.includes('"'))
            setSaveInfo({text: <><Translate>Помилка</Translate>: <Translate>назва школи має містити від 3 до 64 символів і не містити лапок</Translate></>, 
                color: "#ffdddd"});
        else {
            setSaveInfo(null);
            postAPICall('cabinet/newschool', {userId: userId, token: token, title: name}, 
                onSchoolCreated, onSchoolCreateError);
        }
    }

    const getPath = () => {
        var path = new MediaPath(MediaPath.kind_publicSchool);
        path.add(school[0].id);
        path.addStr('desc');
        return path.jsonPath;
    }

    const updateSchool = (evnt) => {
        evnt.preventDefault();
        setSaveInfo(null);
        var apiData = [];
        apiData.push({apifnc: 'cabinet/schoolDesc', 
            params: {userId: userId, token: token, school: school[0].id}});
        rtEditorUploader([description], [getPath()], setProgress, userId, token, apiData, () => {
            setSaveInfo({text: <Translate>Зміни успішно збережено</Translate>, color: "lightblue"});
            navigate('/load/school/' + school[0].id);
        }, onSchoolCreateError);
    }

    return <>
    {school && school.length > 0 && school[0].approvalDate == null && <SchoolMessageAppPendant school={school}/>}
    {school && school.length > 0 && <SchoolCover school={school[0].id} allowChange={true}/>}
    <Form style={{padding: "8px", border: "solid thin lightgray",
        backgroundColor: school && school.length > 0 ? "white" : "#fef5de"}}
        className='top12' onSubmit={(event) => { event.preventDefault();}}>
        <Form.Group className="mb-3" controlId="formName" style={{overflow: "hidden"}}>
            <Form.Label style={{fontWeight: "600"}}><Translate>Назва</Translate></Form.Label>
            <Form.Control value={name} type="text" maxLength="60" disabled={school !== null} 
                placeholder={translate("Напишіть назву вашої школи", dicLang)}
                onChange={e => { setName(WriteHelper.filterSimpleText(e.target.value)); changes(); }} />
            {school !== null && <p style={{color: "cadetblue", fontSize: "smaller", marginTop: "-8px"}}>
                <Translate>Назву школи змінити не можна. Якщо вам потрібно це зробити, зверніться до нашої служби підтримки</Translate>.</p>}
            {school && school.length > 0 && <div className='top12'>
            <SchoolLogo schoolId={school[0].id} allowChange={true} changeLink="/load/cabinet/school"/>
            <Form.Label style={{fontWeight: "600"}}><Translate>Опис</Translate></Form.Label>
            <RTEditor item={description} onChange={(data) => {setDescription(data);}} />
            </div>}
            {school && school.length > 0 && <>
            <div style={{height: "16px"}}/>
            <CabUploadLocalFile path={'/images/school' + school[0].id + '.png'}
                defaultImage='/images/nologo.png' uploadApi='cabinet/uploadLogo' params={{userId: userId,
                token: token, school: school[0].id}} updatable={false} title='Логотип школи для диплома'
                description='Логотип повинен являти собою коло на прозорому тлі. Формат має бути PNG із розміром 475x475 пікселів'
                dimensions={{width: 475, height: 475}}/>
            </>}
            <SchoolTerms agree={agree} setAgree={setAgree} readOnly={school !== null}/>
        </Form.Group>
        <UploadProgress progress={progress}/>
        <div style={{textAlign: "center"}}>
            {school ? 
            <Button onClick={(evnt) => {updateSchool(evnt);}} disabled={progress !== null}><Translate>Підтвердити</Translate></Button> : 
            <Button onClick={(evnt) => {createSchool(evnt);}}><Translate>Створити школу</Translate></Button>
            }
        </div>
        { saveInfo &&
            <div style={{textAlign: "center", fontStyle: "italic", marginTop: "8px",
                backgroundColor: saveInfo.color}}>{saveInfo.text}</div>
        }
    </Form>
    </>
}

export default SchoolForm