import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button"
import { FaFileSignature } from "react-icons/fa6";
import { useWindowSize } from "../AppContext/WindowSize";
import Modal from 'react-bootstrap/Modal';
import { FaFilePdf } from "react-icons/fa6";
import { PiArrowUpBold } from "react-icons/pi";
import { Link } from "react-router-dom";

function TeacherTerms() {
    const [showAgreement, setShowAgreement] = useState(false);
    const [width,] = useWindowSize();
    const [showUp, setShowUp] = useState(false);
    const modalRef = useRef(null);

    const handleDownload = () => {
        const pdfUrl = '/docs/teacherTerms.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'teacherTerms.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (modalRef.current.scrollTop > 200) {
                setShowUp(true);
            } else {
                setShowUp(false);
            }
        };
    
        const currentModal = modalRef.current;
        if (currentModal) {
            currentModal.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (currentModal) {
                currentModal.removeEventListener('scroll', handleScroll);
            }
        };
    }, [showAgreement]);

    return <>
        <Modal show={showAgreement} fullscreen={true} onHide={() => setShowAgreement(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>
                        <p className="fmak large bold">Умови викладання на Платформі для викладачів курсів</p>
                        <div className="top12 bot16 fkenyan">
                            <Button variant="light" style={{color: "#595e69"}} onClick={handleDownload}>
                                <FaFilePdf style={{marginRight: "6px"}}/>PDF версія
                            </Button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#e8e8e8", padding: "0px"}} ref={modalRef}>
                {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                    style={{left: "calc(50% + 360px + 48px)", top: "184px"}} onClick={handleDownload}>
                    <FaFilePdf size="24" style={{transform: "translateX(2px)"}}/>
                </div>}
                {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                    style={{left: "calc(50% - 360px - 120px)", bottom: "84px"}} onClick={() => {
                        modalRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}><PiArrowUpBold size="24"/>
                </div>}
                <div className="terms-div">
                    <p><strong>Умови викладання на Платформі для викладачів курсів<br /><br /></strong></p>
                    <p><strong>Формат роботи викладача на платформі:</strong></p>
                    <ol>
                    <li>Викладач може викладати, надавати свої курси та редагувати їх в різних Школах в необмеженій кількості.</li>
                    <li>Директор Школи додає новий курс до своєї Школи, а також викладача/ів, що приймають у ньому участь. Далі викладач самостійно (або директор) наповнює цей курс матеріалами (відео уроками, супровідними файлами) та заповнює необхідні поля.</li>
                    <li>Викладач може контролювати фінансові звіти, взаємодіяти зі студентами, створювати чати та перевіряти роботи зі свого робочого кабінету.</li>
                    </ol>
                    <p><strong>Винагорода викладача та обрахунки</strong></p>
                    <ol>
                    <li>Платформа утримує 35% від кінцевої вартості кожного проданого Курсу.</li>
                    <li>Всі виплати здійснюються Директору Школи за запитом Директора, але не частіше одного разу на місяць з 20 по 30 число на рахунок, зазначений Директором Школи. Запит на виплату має здійснюватися у період з 1 по 20 число поточного місяця. Виплати, на які поступив запит з 21 числа по останній день місяця, здійснюватимуться наступного місяця з 20 по 30 число.</li>
                    <li>У випадку, якщо на курсі працює більше одного викладача, питання про розподіл коштів між викладачами врегульовується окремо та офіційно поза Платформою. Всі виплати викладачам здійснюються безпосередньо Директором Школи.</li>
                    </ol>
                    <p>Увага: обов&rsquo;язково працюйте з директором Школи на офіційних засадах. Підпишіть угоду або договір про співпрацю, де чітко пропишіть відсоток оплати та усі умови винагороди.</p>
                    <ol start="4">
                    <li>Директор Школи та викладачі отримують щомісячний звіт про зароблені кошти за курс, доступний в особистому кабінеті.</li>
                    </ol>
                    <p><strong>Магазин товарів</strong></p>
                    <ol>
                    <li>Відкривати магазин має право виключно Директор Школи. Ви можете запропонувати йому свої товари. У такому випадку ви не знатимете кількість проданих товарів у разі, якщо вони цифрові. Ви маєте довіряти директору, а також запитувати звіти на умовах попередньої домовленості.</li>
                    <li>Або ви можете створити власну Школу. Усі продукти повинні бути навчальними (що не є курсами) або тематично пов'язаними з одним із курсів, опублікованих у Школі. Платформа залишає за собою право вилучати товари з магазину, які не відповідають цим вимогам.</li>
                    </ol>
                    <p><strong>Акції та знижки</strong></p>
                    <ol>
                    <li>Платформа залишає за собою право самостійно організовувати та проводити акції, надавати знижки та пропонувати інші спеціальні умови для покупців.</li>
                    <li>Всі витрати, пов'язані з проведенням акцій та наданням знижок, Платформа бере на себе.</li>
                    </ol>
                    <p><strong>Права на матеріали</strong></p>
                    <ol>
                    <li>Надаючи матеріали Платформі, викладач передає їй права на розповсюдження, публікацію, рекламу та продаж створених ним матеріалів, включаючи Курси та інші продукти, на необмежений термін або до моменту розірвання Договору.</li>
                    <li>Викладач не має права ділитися або виставляти матеріали, надані Платформі, на інших ресурсах або надавати доступ до них третім особам без попередньої письмової згоди Платформи.</li>
                    <li>Платформа залишає за собою право припинити розповсюдження будь-яких матеріалів, які, на її думку, не відповідають політиці Платформи, чинному законодавству або морально-етичним нормам.</li>
                    </ol>
                    <p><strong>Додаткові послуги</strong></p>
                    <ol>
                    <li>Викладач має можливість скористатися послугами фахівців Платформи за додаткову оплату. Серед доступних послуг:</li>
                    </ol>
                    <ul>
                    <li><strong>Інформаційне наповнення сторінки курсу:</strong> Створення та структуризація текстового контенту для сторінки курсу, включаючи опис, програму, цільову аудиторію, та ключові переваги навчання.</li>
                    <li><strong>Персоналізований дизайн сторінки курсу:</strong> Розробка унікального дизайну сторінки курсу, що включає графічні елементи, кольорові схеми та візуальне оформлення, адаптовані під бренд і тематику курсу.</li>
                    <li><strong>Прошивка від програміста:</strong> Технічне налаштування функціональних елементів сторінки курсу, включаючи інтеграцію з навчальною платформою, створення та оптимізацію форм, кнопок і модулів.</li>
                    <li><strong>Монтаж і дизайн відео:</strong> Професійна обробка відеоматеріалів для курсу, включаючи монтаж, додавання титрів, спецефектів, анімацій та графічних елементів, що підвищують якість відеоконтенту.</li>
                    <li><strong>Дизайн креативів і заставок для курсів і продуктів:</strong> Створення візуальних елементів, таких як обкладинки, банери, заставки і промо-матеріали, які підкреслюють унікальність курсу та сприяють залученню студентів.</li>
                    <li><strong>Презентаційне відео для курсу:</strong> Розробка короткого рекламного відео, яке представляє курс, його ключові переваги та особливості, сприяючи підвищенню зацікавленості потенційних студентів.</li>
                    <li><strong>Редакція тексту курсу і Школи:</strong> Вичитка та редагування всіх текстових матеріалів курсу та школи для забезпечення їх граматичної та стилістичної правильності, ясності та відповідності стандартам Платформи.</li>
                    <li><strong>Індивідуальний супровід створення курсу:</strong> Персональна підтримка та консультації на всіх етапах розробки курсу, від планування і створення контенту до його публікації та просування на Платформі.</li>
                    <li><strong>Таргетолог:</strong> Налаштування та управління рекламними кампаніями в соціальних мережах та інших онлайн-каналах для просування курсу та залучення цільової аудиторії.</li>
                    <li><strong>Ілюстратор:</strong> Створення унікальних ілюстрацій та графічних матеріалів, що додають візуальної привабливості курсу та сприяють кращому засвоєнню матеріалу студентами.</li>
                    </ul>
                    <ol start="2">
                    <li>Детальну інформацію про ціни, види робіт, а також приклади робіт можна знайти у вкладці <Link to="/services">Послуги Платформи</Link>.</li>
                    </ol>
                    <p><strong>Відповідальність за якість та наповнення курсу</strong></p>
                    <ol>
                        <li>Всі матеріали курсу, включаючи його зміст, структуру, інформацію на сторінці курсу, опис, назву та візуальні матеріали, створюються та підтримуються викладачем.</li>
                        <li>Викладач несе повну відповідальність за привабливість свого курсу, його актуальність та відповідність очікуванням цільової аудиторії.</li>
                        <p>Платформа надає технічну можливість розміщення курсу, але успіх курсу значною мірою залежить від зусиль викладача щодо його розвитку та просування.</p>
                    </ol>
                    <p><strong>Просування курсів</strong></p>
                    <ol>
                        <li>Платформа підтримує курси через свої маркетингові канали. Але для залучення більшої кількості учнів, викладачам рекомендується активно займатися їхнім просуванням, використовуючи різноманітні маркетингові інструменти. Що може включати в себе: рекламу на сторінку курсу, просування у власних соціальних мережах, залучення аудиторії через веб-ресурси та інші можливі канали комунікації.</li>
                        <p>Ми здійснюємо рекламу самої платформи, а також окремих курсів. Але ефективність набору учнів значною мірою залежить від особистих зусиль викладача.</p>
                        <p>Викладач, який докладає зусиль щодо популяризації та просування свого курсу, має більше шансів на успішний набір учнів та досягнення фінансових результатів.</p>
                    </ol>
                    <p><strong>Інші умови</strong></p>
                    <ol>
                    <li>Викладач погоджується з умовами цього Договору, ставлячи відповідну позначку на сторінці реєстрації або публікації Курсу на Платформі.</li>
                    <li>Ознайомившись з цими умовами, ви погоджуєтесь бути викладачем на Платформі.</li>
                    <li>Якщо ви бажаєте припини викладання курсу, зверніть до директора Школи, або, у разі не виконання вашого звернення, напишіть у команду підтримки Пдатформи за цією адесою: support@magistrika.com</li>
                    </ol>
                </div>
            </Modal.Body>
        </Modal>
        <div style={{textAlign: "right", margin: "8px 16px"}}>
            <Button variant="secondary" size="sm" onClick={() => { setShowAgreement(true); }}>
                <FaFileSignature size="20"/><br/>
                <span className="small">Умови викладання на Платформі<br/>для викладачів курсів</span>
            </Button>
        </div>
    </>
}

export default TeacherTerms