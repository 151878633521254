import { useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import { useEffect, useContext, useRef } from "react";
import { getAPICall } from "../Components/APICall";
import { AppContext } from "../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import PostMessage from "../Components/PostMessage";
import PlanPrice from "./PlanPrice";
import { Link } from "react-router-dom";
import Translate, {translate} from "../AppContext/Translate";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { getAPIurl } from "../Components/APICall";
import { downloadFileFromURL } from "../Components/APICall";
import { TbShoppingCartPlus } from 'react-icons/tb';
import Add2Cart from "../AppContext/Add2Cart";
import { useWindowSize } from "../AppContext/WindowSize";
import ScrollDots from "./ScrollDots";
import ItemOpinions from "./ItemOpinions";

function size2text(size, dicLang) {
    if (size < 1000) return size + " " + translate('Кб', dicLang);
    var sizeInMB = (size / 1024).toFixed(2);
    return sizeInMB + " " + translate('Мб', dicLang);
}

function DownloadProduct({id, data}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [downloaded, setDownloaded] = useState(false);

    const download = (id) => {
        const url = getAPIurl('user/downloadProduct', {userId: userId, token: encodeURIComponent(token),
            itemId: id, filename: encodeURIComponent(data.name)});
        downloadFileFromURL(url, data.name, () => { setDownloaded(true); }, 
            () => { alert(translate('Помилка завантаження', dicLang) + ". " +
                translate('Якщо проблема не зникає, зверніться до нашої служби підтримки', dicLang)); });
    }
 
    return <Card className="text-center" style={{width: "274px", margin: "8px"}}>
        <Card.Header style={{textTransform: "uppercase", display: "flex"}}>
            <img alt="" src={"/lang/" + data.lang + ".png"} width={24}/>
            <div style={{width: "100%", textAlign: "center"}}>{data.ext}</div>
        </Card.Header>
        <Card.Body>
            <Card.Title style={{fontSize: "large"}}>{data.name}</Card.Title>
            <Card.Text>{data.size}</Card.Text>
            <Button variant="primary" disabled={downloaded} onClick={() => {download(id);}}><Translate>Завантажити</Translate></Button>
        </Card.Body>
    </Card>
}

function Product() {
    var id = useParams()["id"];
    if (id) id = parseInt(id);
    const [width,] = useWindowSize();
    const { userId, token, lang, dicLang } = useContext(AppContext);
    const [data, setData] = useState(null);
    const [purchased, setPurchased] = useState(false);
    const [files, setFiles] = useState(null);
    const [numImages, setNumImages] = useState(null);
    const [videoLink, setVideoLink] = useState(null);
    const [selectedImage, setSelectedImage] = useState(0);
    const [containerHeight, setContainerHeight] = useState(196);
    const containerRef = useRef(null);

    const updateHeight = () => {
        if (containerRef.current) {
            setContainerHeight(containerRef.current.clientHeight - 16);
        }
    };

    useEffect(() => {
        const purchasedItemsOk = (items, data) => {
            if (items) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].item === id) {
                        setPurchased(true);
                        if (data.digital === 1 && data.filename) {
                            const parts = data.filename.split('|');
                            var list = [];
                            list.push({ext: parts[0], size: size2text(parseInt(parts[1]), dicLang), name: parts[2]});
                            setFiles(list);
                        }
                        break;
                    }
                }
            }
        }
        if (id) {
            getAPICall('shop/item', {item: id},
                (data) => {
                    if (data && data.length > 0) {
                        const row = data[0];
                        setData(row);
                        if (row.digital === 1 && userId > 0) {
                            getAPICall('user/purchasedItems', {userId: userId, token: encodeURIComponent(token)},
                                (items) => { purchasedItemsOk(items, row); } );
                        }
                    }
                });
            getAPICall('item/addPics', { itemId: id }, (data) => {
                if (data.length > 0) {
                    setNumImages(data[0].numImages);
                    setVideoLink(data[0].videolink ? data[0].videolink : null);
                } else {
                    setNumImages(0);
                }
            });
        }
    }, [id, lang, userId, token, dicLang]);
    
    const getSelectedImage = () => {
        if (selectedImage === 0) {
            return <img alt="" src={"/items/item" + id + ".jpg"} width="100%" height="100%"/>;
        } else if (selectedImage <= numImages) return <img alt="" src={"/items/item" + id + "_" + selectedImage + ".jpg"}
            width="100%" height="100%" />;
        else {
            let height = containerHeight;
            let w = height * 1.45;
            return <iframe width={w + "px"} height={height + "px"} src={"https://www.youtube.com/embed/" + videoLink}
                title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />; 
        }
    }

    const nextImage = () => {
        let max = numImages !== null ? numImages : 0;
        if (videoLink !== null) max++;
        setSelectedImage((selectedImage + 1) % (max + 1));
    }

    useEffect(() => {
        const checkContainer = setInterval(() => {
            if (containerRef.current) {
                updateHeight();
                clearInterval(checkContainer);
            }
        }, 500);
        return () => clearInterval(checkContainer);
    }, [width]);

    var cursor = (numImages !== null && numImages > 0) || videoLink !== null ? "pointer" : "default";
    
    return !id ? <Navigate to="/" replace={true} /> :  
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu />
        {data ?
        <div>
            <div style={{backgroundColor: "#fac032"}} className="pad16">
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{paddingRight: "16px"}}>
                        <PostMessage msg={data.name} open={false} sep={false} textStyle="bigTextPost fkenyan"/>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <img className="schoolLogo" alt="Logo" src={'/schools/logo' + data.school + '.png'}/>
                        <Link to={"/school/" + data.school}><div className='itemSchool' style={{textAlign: "center"}}>{data.schoolName}</div></Link>
                    </div>
                </div>
            </div>
            <hr style={{marginTop: "0px"}}/>
            <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexWrap: "wrap",
                marginBottom: "32px"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} className="itemPicture">
                    <div className="shineHover" ref={containerRef} style={{backgroundColor: "white", width: "100%",
                            boxShadow: "2px 2px 4px #0000003b", paddingBottom: "70%"}}>
                        {numImages !== null && <div style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0}}>
                            <figure style={{overflow: "hidden", margin: "8px", cursor: cursor, display: "flex", 
                                justifyContent: "center"}} onClick={nextImage}>
                                {getSelectedImage()}
                            </figure>
                        </div>}
                    </div>
                    {numImages !== null && <ScrollDots selectedImage={selectedImage} setSelectedImage={setSelectedImage}
                        numImages={numImages} videoLink={videoLink}/>}
                </div>
                <div className="itemPicture" style={{marginLeft: "24px"}}>
                    <PostMessage msg={data.description} open={false} sep={false} textStyle="smallTextPost"/>
                    {purchased ? 
                    <>
                        <hr/>
                        <div>
                            <h3><Translate>Ви вже отримали цей товар</Translate>:</h3>
                            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                            { files && files.map((item, i) => {
                                return <DownloadProduct key={i} id={id} data={item}/>
                              })
                            }
                            </div>
                        </div>
                    </> :
                    <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                        <PlanPrice price={data.price} prev={null}/>
                        <Add2Cart data={{kind: 'item', itemId: id, digital: data.digital}}
                            buttonStyle={{backgroundColor: "#12c191", color: "white",
                                padding: "8px 16px 10px 16px", borderRadius: "8px", cursor: "pointer"}}
                                content={<>Купити <TbShoppingCartPlus/></>} />
                    </div>
                    }
                </div>
            </div>
            <ItemOpinions itemId={id} width={width} />
        </div>
        : <div style={{margin: "16px"}}><BeatLoader size={24} color="#0d6efd" /></div>
        }
        <Footer/>
    </div>
}

export default Product