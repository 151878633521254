import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall, getAPIurl } from "../Components/APICall";
import Decline from "./Decline";
import SyncLoader from "react-spinners/SyncLoader";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import HomeButton from "../Components/HomeButton";

function GiftDone() {
    var orderId = useParams()["id"];
    const { userId, token } = useContext(AppContext);
    const [accepted, setAccepted] = useState(!orderId ? false : null);
    const [orderData, setOrderData] = useState(null);

    const card = orderData ? getAPIurl('gift/card', {design: orderData.design, code: orderData.code}) : null;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (orderId) {
                getAPICall('gift/paid', {userId: userId, token: encodeURIComponent(token), orderId: orderId},
                    (data) => {
                        if (data && data.length > 0) {
                            setAccepted(true);
                            setOrderData(data[0]);
                        } else setAccepted(false);
                    }, () => { setAccepted(false); });
            }
        }, 3000);
        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, userId, token]);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        {accepted === null ? <div className="pad12">
            <SyncLoader/>
        </div> : (accepted === true ? 
            <div className="magSection" style={{backgroundColor: "#d9f5ed", maxWidth: "800px", margin: "48px auto", flexDirection: "column", alignItems: "center"}}>
                <img alt="" src="/logo192.png" width="84px" style={{marginTop: "-56px"}}/>
                <p className="top18 bold large"><img alt="" src="/icons/check.png" width="48px" className="right18"/><Translate>Дякуємо! Ваше замовлення прийнято</Translate>.</p>
                {orderData && <div style={{width: "100%", textAlign: "left", padding: "12px"}}>  
                    <p className="top24"><b><Translate>Інформація про вашу покупку</Translate>:</b></p>
                    <ul>
                        <li><b><Translate>Курс</Translate>: </b>{orderData.courseTitle}</li>
                        <li><b><Translate>Пакет</Translate>: </b>{orderData.planTitle}</li>
                        <li><b><Translate>Сума оплати</Translate>: </b>{orderData.price} грн.</li>
                    </ul>
                    <p><b><Translate>Як скористатися подарунковим сертифікатом?</Translate></b></p>
                    <p><Translate>Ваш подарунковий сертифікат створено! Ви можете надіслати його отримувачу.</Translate></p>
                    <p className="top24"><b><Translate>Отримувач має</Translate>:</b></p>
                    <ol>
                        <li><Translate>Перейти за посиланням</Translate>: <a href="https://magistrika.com/getgift">magistrika.com/getgift</a></li>
                        <li><Translate>Зареєструватися або увійти на платформу</Translate>.</li>
                        <li><Translate>Ввести унікальний код активації, що на сертифікаті</Translate>:</li>
                    </ol>
                    {card && <p><img alt="сертифікат" src={card} style={{maxWidth: "320px", width: "100%", height: "auto"}} /></p>}
                    <p className="top36"><Translate>Якщо у вас виникнуть запитання, наша служба підтримки завжди готова допомогти. Напишіть нам на</Translate> <a href="mailto:support@magistrika.com">support@magistrika.com</a>.</p>
                    <p className="top24"><Translate>З найкращими побажаннями</Translate>,</p>
                    <p className="bot24"><Translate>Команда</Translate> magistrika.com</p>
                    <HomeButton/>
                </div>}
            </div> 
            : <Decline/>)}
        <Footer/>
    </div>
}

export default GiftDone;