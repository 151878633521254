import Translate, { formatDate } from "../../AppContext/Translate"
import ExpandButton from "../../Components/ExpandButton";
import { useState } from "react";
import OrderDetail from "./OrderDetail";
import { ORDER_CHAR } from "../../serverData";

export function Box({title, value, align="left"}) {
    return <div style={{margin: "4px 16px 4px 0px", textAlign: align}}>
        <p className="cartItemTitle">{title}</p>
        <div>{value}</div>
    </div>
}

export function encodeOrderNumber(id) {
    var str = id.toString(16).toUpperCase();
    if (str.length < 10) {
        str = ORDER_CHAR + str;
        var next = id + 1;
        while (str.length < 10) {
            var aux = next.toString(16).toUpperCase();
            str = aux.charAt(aux.length - 1) + str;
            next += 7;
        }
    }
    return str;
}

function Order({data, expandedOrder=null}) {
    const [expanded, setExpanded] = useState(expandedOrder && expandedOrder === data.id ? true : false);

    return <div className="orderFrame">
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex"}}>
                <Box title={<Translate>Дата</Translate>} value={formatDate(data.orderDate)}/>
                <Box title={<Translate>Замовлення</Translate>} value={encodeOrderNumber(data.id)}/>
            </div>
            <ExpandButton expanded={expanded} setExpanded={setExpanded} />
        </div>
        {expanded && <>
            <hr style={{marginTop: "0px"}}/>
            <OrderDetail order={data}/>
        </>}
    </div>
}

export default Order