import { AttentionSeeker } from "react-awesome-reveal";
import PostReactionOrComment from "./PostReactionOrComment";
import PostReactions from "./PostReactions";
import PostMessage from "../../Components/PostMessage";
import BookmarkState from "../../Components/BookmarkState";

function ChatNormalPost({post, refreshData, userId, token, student, courseId, setExpanded, reactions, comments, 
    changeExpanded, path, highlightPost, highlightComment}) {
    
    const elements = <div className="chatPost">
        {student.student && student.student >= 0 && 
          <BookmarkState context="post" kind={post.state} data={post.post} refreshData={refreshData} course={courseId}/>}
        <PostMessage msg={post["message"]} path={path} open={false} sep={false}/>
        {(reactions.length > 0 || comments.length > 0) && 
            <><hr style={{marginTop: "8px", marginBottom: "8px"}}/>
            <PostReactions reactions={reactions} numComments={comments.length} changeExpanded={changeExpanded}
                postId={post["post"]} student={student}/></>}
        {student.plan === 1 && <hr style={{marginTop: "8px", marginBottom: "8px"}}/>}
        <PostReactionOrComment post={post["post"]} userId={userId} token={token} student={student}
            setExpanded={setExpanded} courseId={courseId} parentPost={0}/>
        </div>
  
    const bounce = (ref) => {
        if (ref && highlightPost === post.post && !highlightComment) {
            ref.scrollIntoView();
        }
    }

    return <div ref={ref => { bounce(ref); }}>
    { highlightPost === post.post && !highlightComment ?
      <AttentionSeeker effect="bounce">
        {elements}
      </AttentionSeeker> : 
      <>
        {elements}
      </> }
    </div>
}

export default ChatNormalPost