import { useState, useContext, useEffect, lazy, Suspense } from 'react';
import Card from 'react-bootstrap/Card';
import { IoBagAddOutline } from 'react-icons/io5';
import Translate, { translate, getLanguagesData } from '../../AppContext/Translate';
import ExpandButton from '../../Components/ExpandButton';
import Form from 'react-bootstrap/Form';
import { AppContext } from '../../AppContext/AppContext';
import NumericInput from '../../Components/NumericInput';
import Dropdown from 'react-bootstrap/Dropdown';
import WriteHelper from '../../Components/WriteHelper';
import RTEditor from '../../Components/RTEditor';
import Button from "react-bootstrap/Button";
import { IoTrashOutline } from "react-icons/io5";
import { postAPICall, getAPICall, postAPIUploadProgress } from '../../Components/APICall';
import { LuFileDigit, LuPackage } from "react-icons/lu";
import ChooseFileButton from '../../Components/ChooseFileButton';
import UploadProgress from '../../Components/UploadProgress';
import { confirm } from "../../Components/ConfirmFunction";
import { getFileExtension } from '../../Components/MimeIcon';
import ProductMember from './ProductMember';
import PostMessage from '../../Components/PostMessage';
import AdditionalItemImages from './AdditionalItemImages';

const UploadPicture = lazy(() => import('../../Components/UploadPicture'));

function EditProduct({school, itemId, forceUpdate}) {
    var { userId, token, lang, dicLang } = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const [digital, setDigital] = useState(false);
    const [reward, setReward] = useState(false);
    const [changes, setChanges] = useState(false);
    const [stock, setStock] = useState(0);
    const [price, setPrice] = useState(0);
    const [selectedLang, setSelectedLang] = useState(lang);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [number, setNumber] = useState(1);
    const [showChangePicture, setShowChangePicture] = useState(false);
    const [filename, setFilename] = useState(null);
    const [progress, setProgress] = useState(null);
    const [team, setTeam] = useState([]);
    const [email, setEmail] = useState('');
    const [showAddPics, setShowAddPics] = useState(false);

    useEffect(() => {
        if (itemId >= 0) {
            getAPICall('item/info', {item: itemId}, (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    setDigital(row.digital > 0);
                    setPrice(row.price);
                    setReward(row.availableAsReward);
                    setStock(row.stock);
                    setSelectedLang(row.lang);
                    setName(row.name);
                    setFilename(row.filename);
                    (async () => {
                        if (forceUpdate) {
                            const desc = await WriteHelper.parseMessage(row.description, null);
                            setDescription(desc.text);
                        } else setDescription(row.description);
                    })();
                }
            });
            getAPICall('item/team', {item: itemId}, (data) => {
                if (data) {
                    setTeam(data);
                }
            });
        }
    }, [itemId, forceUpdate]);

    const saveError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const createNewItem = () => {
        postAPICall('item/new', {userId: userId, token: token, school: school, digital: digital ? 1 : 0,
            reward: reward ? 1 : 0, price: price, name: name, description: WriteHelper.encode(description),
            lang: selectedLang, stock: stock}, (data) => {
            if ('id' in data) {
                setChanges(false);
                setDigital(false);
                setReward(false);
                setStock(0);
                setPrice(0);
                setSelectedLang(lang);
                setName('');
                setDescription('');
                setFilename(null);
                forceUpdate();
            } else saveError();
        }, saveError);
    }

    const saveMember = (index) => {
        if (index < team.length) {
            const m = team[index];
            postAPICall('item/saveMember', {userId: userId, token: token, school: school, 
                owner: m.owner, itemId: itemId, percentage: m.percentage}, (data) => {
                if (!data || !('error' in data) || data.error !== 'none') saveError();
                else saveMember(index + 1);
            }, saveError);
        } else {
            postAPICall('item/update', {userId: userId, token: token, item: itemId, school: school,
                reward: reward ? 1 : 0, price: price, description: WriteHelper.encode(description),
                stock: stock, lang: selectedLang, name: name}, (data) => {
                if ('error' in data && data.error === 'none') {
                    setChanges(false);
                    alert(translate("Зміни успішно збережено", dicLang));
                } else saveError();
            }, saveError);
        }
    }

    const save = () => {
        if (itemId < 0) createNewItem();
        else {
            var total = 0;
            for (var i = 0; i < team.length; i++) {
                total += team[i].percentage;
            }
            if (total === 100) saveMember(0);
            else alert(translate("Помилка", dicLang) + ": " + translate("відсоток гонорару продукт в сумі має складати 100%", dicLang));
        }
    }

    const deleteItem = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити продукт?', dicLang)})) {
            postAPICall('item/delete', {userId: userId, token: token, school: school, item: itemId}, (data) => {
                if (!data || !('error' in data) || data.error !== 'none') saveError();
                forceUpdate();
            }, saveError);
        }
    }

    const deleteFile = async () => {
        var f = filename.split('|');
        if (f.length !== 3) { saveError(); return; }
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити файл?', dicLang)})) {
            postAPICall('item/deleteFile', {userId: userId, token: token, school: school, item: itemId,
                filename: f[2]}, (data) => {
                    if (!data || !('error' in data) || data.error !== 'none') saveError();
                    setFilename(null);
                }, saveError);
        }
    }

    const fileChange = (evnt) => {
        if (!evnt) {
            deleteFile(); 
            return; 
        }
        const file = evnt.target.files[0];
        if (!file) { saveError(); return; }
        const size = file.size / 1048576; 
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
                const fileName = getFileExtension(file.name) + '|' + Math.round(size * 1024).toString() + '|' + file.name;
                postAPIUploadProgress('item/upload', {name: file.name, buffer: result},
                    {userId: userId, token: token, school: school, item: itemId, filename: fileName},
                    () => {
                        setFilename(fileName);
                        setProgress(null);
                    },
                    () => { setProgress(null); saveError(); },
                    (percent) => { setProgress({file: file.name, percent: percent, action: 'upl'});
                });
            } else {
                saveError();
            }
        }
        fileReader.readAsDataURL(file);
    }

    const getFilename = () => {
        if (filename) {
            const f = filename.split('|');
            if (f.length === 3) {
                return {ext: f[0], size: parseFloat(f[1]), name: f[2]};
            }
        }
        return null;
    }

    const newMemberError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("за вказаною електронною адресю немає зареєстрованого користувача", dicLang));
    }

    const newMember = (email) => {
        const percentage = team.length === 0 ? 100 : 0; 
        postAPICall('item/newMember', {userId: userId, token: token, itemId: itemId, email: email,
            percentage: percentage, school: school},
            (data) => {
                if (data && 'id' in data && 'name' && data) {
                    setTeam([...team, {owner: data.id, name: data.name, percentage: percentage}]);
                } else newMemberError();
            }, newMemberError); 
    }

    const setPercentage = (index, value) => {
        const nextTeam = team.map((m, i) => {
            if (i === index) {
                var copy = {...m};
                copy.percentage = value;
                return copy;
            }
            else return m;
        });
        setTeam(nextTeam);
    }

    const deleteMember = async (index) => {
        const m = team[index];
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити цього члена?', dicLang)})) {
            postAPICall('item/deleteMember', {userId: userId, token: token, itemId: itemId, owner: m.owner,
                school: school}, (data) => {
                    var copy = [...team];
                    copy.splice(index, 1);
                    setTeam(copy);
                }, saveError);
        }
    }

    const disabled = forceUpdate === null;

    return <>
        {showAddPics && <AdditionalItemImages itemId={itemId} handleClose={()=>{ setShowAddPics(false); }}
            itemName={name} school={school}/>}
        <Card bg="light" style={{ width: '24rem', maxWidth: "100%", margin: "6px" }}>
            <Card.Header style={{display: "flex", justifyContent: "space-between"}}>
                {itemId < 0 ? <div>
                    <IoBagAddOutline size="18" color="darkslateblue" style={{marginTop: "-5px", marginRight: "6px"}}/>
                    <Translate>Створіть новий продукт</Translate>
                </div> : <div>
                    {digital ? <LuFileDigit size="18" color="cornflowerblue" style={{marginTop: "-5px", marginRight: "6px"}}/> :
                    <LuPackage size="18" color="blueviolet" style={{marginTop: "-5px", marginRight: "6px"}}/>}
                    <Translate>{digital ? "Цифровий продукт" : "Фізичний продукт"}</Translate>
                </div>}
                <ExpandButton expanded={expanded} setExpanded={setExpanded}/>
            </Card.Header>
            {itemId >= 0 && <>
                <img alt="" src={"/items/item" + itemId + ".jpg?n=" + number} style={{width: "100%", cursor: "pointer"}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/items/noitem.jpg";
                }} onClick={() => { setShowChangePicture(true); } } />
                <Suspense>
                    <UploadPicture show={showChangePicture} handleClose={()=>{ setShowChangePicture(false); }}
                        width={720} height={498} title="Фото товару"
                        apiFnc="item/photo" forceUpdate={() => { setNumber(current => current + 1); }}
                        params={{item: itemId, school: school}}/>
                </Suspense>
                <Button size='sm' variant='link' onClick={() => { setShowAddPics(true); }}>
                    <Translate>Додайте більше фотографій
                </Translate></Button>
            </>}
            {expanded &&
            <Card.Body>            
                {itemId < 0 && <Form.Check checked={digital} type="switch" label={translate("Цифровий продукт", dicLang)}
                    onChange={() => { setDigital(!digital); }}/>}
                {digital &&
                <Form.Check disabled={disabled} checked={reward} type="switch" label={translate("Доступно як винагорода", dicLang)}
                    onChange={() => { setReward(!reward); setChanges(true); }}/>}
                {!digital && <div style={{display: "flex", alignItems: "baseline", marginTop: "8px"}}>
                    <span style={{marginRight: "6px"}}><Translate>Склад</Translate>:</span>
                    <NumericInput value={stock} isDisabled={disabled} 
                        onChange={(e) => { setStock(e); setChanges(true); }} step={1} precision={0} maxWidth={128}/>
                </div>}
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <span style={{marginRight: "6px"}}><Translate>Ціна</Translate> (грн):</span>
                    <NumericInput value={price} isDisabled={disabled}
                        onChange={(e) => { setPrice(e); setChanges(true); }} step={1} precision={0} maxWidth={128}/>
                </div>
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <span style={{marginRight: "8px"}}><Translate>Мова</Translate></span>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={{maxWidth: "100%"}}>
                            {selectedLang}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {getLanguagesData().map((m, i) => { 
                                return <Dropdown.Item key={i} disabled={disabled} 
                                    onClick={() => { setSelectedLang(m.id); setChanges(true); }}>
                                    {m.name}
                                </Dropdown.Item>})}
                        </Dropdown.Menu>
                    </Dropdown>
                    <img alt="" src={"/lang/" + selectedLang + ".png"} width={24} style={{marginLeft: "8px"}}/>
                </div>
                <hr style={{marginBottom: "0px"}}/>
                <Form.Label style={{fontWeight: "600", marginTop: "8px"}}><Translate>Назва</Translate></Form.Label>
                <Form.Control value={name} type="text" maxLength="96" disabled={disabled}
                    onChange={e => { setName(WriteHelper.filterSimpleText(e.target.value)); setChanges(true); }} />
                <Form.Label style={{fontWeight: "600", marginTop: "8px"}}><Translate>Опис</Translate></Form.Label>
                {disabled ? <PostMessage msg={description} path={null} open={false} sep={false} textStyle='smallTextPost' /> : 
                <RTEditor item={{text: description, files: []}}
                    onChange={(data) => { console.log(data.text); setDescription(data.text); setChanges(true); }}
                    allowFiles={false} allowVideos={false} allowImages={false}/>}
                {digital && itemId >= 0 && !disabled && <>
                    <ChooseFileButton ext="" fileSrc={getFilename()}
                        fileChange={(e) => { fileChange(e);}} />
                    <UploadProgress progress={progress}/>
                    </>
                }
                {itemId >= 0 && <>
                    <hr/>
                    {team.map((member, i) => {
                        return <ProductMember key={i} member={member} index={i} setPercentage={setPercentage}
                            deleteMember={deleteMember} setChanged={setChanges} canChange={forceUpdate !== null}/>
                    })}
                    {!disabled && <>
                        <Form.Label><Translate>Введіть адресу електронної пошти зареєстрованого користувача, щоб додати його до списку творців продукту (або більше, якщо продукт має кілька творців).</Translate></Form.Label>
                        <div style={{display: "flex", alignItems: "flex-start"}}>
                            <Form.Control value={email} type="text" style={{maxWidth: "320px", marginRight: "8px"}}
                                onChange={(e) => { setEmail(e.target.value); }}/>
                            <Button disabled={email.length===0} onClick={() => { newMember(email); }}><Translate>Додати</Translate></Button>
                        </div>
                    </>}
                </>}
                {!disabled && <>
                    <hr/>
                    <div style={{display: "flex", justifyContent: "space-evenly"}} className='top12'>
                        <Button disabled={!changes} onClick={save}>
                            <Translate>{itemId < 0 ? "Створіть новий продукт" : "Зберегти зміни"}</Translate></Button>
                        {itemId >= 0 && 
                        <Button onClick={deleteItem} variant="secondary"><IoTrashOutline style={{marginTop: "-2px"}} /></Button>}
                    </div>
                </>}
            </Card.Body>}
        </Card>
    </>
}

export default EditProduct