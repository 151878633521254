import { useContext, useRef, useState, lazy, useEffect, Suspense } from "react";
import { AppContext } from "../AppContext/AppContext";
import BeatLoader from "react-spinners/BeatLoader";
import Translate, { translate } from "../AppContext/Translate";
import { getAPICall, postAPICall } from "../Components/APICall";
import validator from 'validator'
import CartTotals, { calculateTotalOrder } from "./CartTotals";
import Menu from "../Menu/Menu";
import EmptyCart from "./EmptyCart";
import Table from 'react-bootstrap/Table';
import CartItem from "./CartItem";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from 'react-bootstrap/Dropdown';
import Footer from "../Components/Footer";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import WriteHelper from "../Components/WriteHelper";
import 'react-phone-number-input/style.css';

const MapsSearchBox = lazy(() => import('../Components/MapsSearchBox'));
const PhoneInput = lazy(() => import('react-phone-number-input'));
    
function Cart() {
    const { userId, token, cart, dicLang, userName } = useContext(AppContext);
    const [promos, setPromos] = useState([]);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [prices, setPrices] = useState(() => {
      var p = [];
      for (var i = 0; i < cart.length; i++) p.push(0);
      return p;
    });
    const [purchaseData, setPurchaseData] = useState(null);
    const [clientName, setClientName] = useState(userName);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [error, setError] = useState(0);
    const formRef = useRef(null);
    const inputRef = useRef();
    const Loading = () => <BeatLoader size={18} color="#0d6efd" />;

    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) {
          setAddress(place.formatted_address);
        }
    }

    var subtotal = 0;
    for (var i = 0; i < cart.length; i++) {
      subtotal += cart[i].amount * prices[i];
    }

    useEffect(() => {
        const getPromosSuccess = (data) => {
          if (data && data.length > 0)
            setPromos(data)
        }
        if (userId >= 0) {
          getAPICall('user/promos', {userId: userId, token: encodeURIComponent(token)}, getPromosSuccess);
        }
    }, [userId, token]);
  
    useEffect(() => {
        if (formRef && purchaseData) {
          formRef.current.submit();
        }
    }, [formRef, purchaseData]);
  
    const getPromoDescription = (promo) => {
        var desc = translate('Знижка', dicLang) + ": " + promo.discount;
        if (promo.kind === 0) desc += ' грн';
        else desc += ' %'
        return desc;
    }
  
    const getPromosList = () => {
        var plist = [];
        if (promos && promos.length > 0) {
          plist.push({id: -1, name: '---'});
          promos.forEach(function(promo) {
            plist.push({id: promo.id, name: getPromoDescription(promo)});
          });
        }
        else plist.push({id: -1, name: translate('У вас наразі немає промо-акцій', dicLang)});
        return plist;
    }
  
    const onPromoChanged = (value) => {
        if (value.id < 0) setSelectedPromo(null);
        else {
          promos.forEach(function(promo) {
            if (promo.id === value.id)
              setSelectedPromo(promo);
          });
        }
    }
  
    const updateSubtotal = (index, price) => {
        if (index >= 0 && index < cart.length) {
          setPrices( prevState => {
              let newState = [...prevState]
              newState[index] = price;
              return newState.slice(0, cart.length);
            }
          );
        }
    }
  
    const lineToStr = (line, price) => {
      if (line.kind === 'item') {
        return 'item,' + line.itemId + ',' + line.amount + ',' + price;
      } else {
        return 'course,' + line.courseId + ',' + line.groupId + ',' + price + ',' + line.packageId;
      }
    }
  
    const purchase = () => {
        const total = calculateTotalOrder(subtotal, selectedPromo);
        const promo = selectedPromo ? selectedPromo.id : -1;
        var lines = '';
        for (var i = 0; i < cart.length; i++) {
          if (lines.length > 0) lines += '|';
          lines += lineToStr(cart[i], prices[i]);
        }
        if (physicalProducts && !validator.isMobilePhone(phone)) {
          setError(1);
          return;
        }
        if (physicalProducts && address.length < 5) {
          setError(2);
          return;
        }
        if (physicalProducts && clientName.split(' ').length < 2) {
          setError(3);
          return;
        }
        postAPICall('orders/hash', {userId: userId, token: token, total: total.total, promo: promo,
          lines: lines, clientName: WriteHelper.filterSimpleText(clientName), phone: WriteHelper.filterSimpleText(phone),
          address: WriteHelper.filterSimpleText(address), comment: WriteHelper.filterSimpleText(comment)}, (data) => {
          setPurchaseData({...data, total: total.total});
        });
    }

    var physicalProducts = false;
    for (i = 0; i < cart.length; i++) {
      if (cart[i].digital === 0) {
        physicalProducts = true;
        break;
      }
    }

    return !purchaseData ? 
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <div style={{textAlign: "center"}}>
            <h1 className="top24">
              <img alt="" src="/icons/shop.png" style={{width: "72px", verticalAlign: "text-bottom", marginRight: "8px"}}/>
              <Translate>Мій кошик</Translate>
            </h1>
        </div>
        { cart.length === 0 ? <EmptyCart/> :
            <div className="cartItemDiv">
                <Table striped borderless hover>
                    <thead>
                        <tr className="cartTableHeader">
                            <th colSpan="3" style={{color: "inherit", backgroundColor: "inherit", textAlign: "center"}}>
                              <Translate>Продукт</Translate></th>
                            <th style={{color: "inherit", backgroundColor: "inherit", textAlign: "center"}}>
                              <Translate>Ціна</Translate></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        cart.map((item, i) => {
                            return <CartItem key={i} index={i} item={item} updateSubtotal={updateSubtotal}/>
                        })
                        }
                    </tbody>
                </Table>
                <hr/>
                <Row>
                    <Col sm="6" style={{padding: "12px"}}>
                        <p><Translate>Виберіть акцію</Translate>:</p>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic"
                              style={{maxWidth: "400px", width: "300px", backgroundColor: "#f2f2f2"}}>
                                {selectedPromo !== null ? getPromoDescription(selectedPromo) : ''}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {getPromosList().map((m, i) => { 
                                    return <Dropdown.Item key={i} onClick={() => onPromoChanged(m)}>{m.name}</Dropdown.Item>})}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col sm="6" style={{padding: "8px"}}>
                        <CartTotals subtotal={subtotal} promo={selectedPromo}/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginTop: "24px", marginBottom: "36px", textAlign: "center"}}>
                    { userId >= 0 ? <>
                        {physicalProducts && <div style={{border: "gray thin solid", borderRadius: "12px", 
                            padding: "10px", marginBottom: "12px"}}>
                            <h4><Translate>Адреса доставки</Translate></h4>
                            <hr/>
                            <div style={{display: "flex", alignItems: "baseline"}}>
                                <span style={{marginRight: "6px"}}><Translate>Ім'я</Translate></span>
                                <input type="text" value={clientName} className="form-control" placeholder=""
                                    onChange={(e) => setClientName(e.target.value) } style={{maxWidth: "290px",
                                    borderRadius: "0px", borderColor: "rgb(118, 118, 118)"}}/>
                            </div>
                            {error === 3 && <p style={{color: "red", textAlign: "left"}}><Translate>Помилка</Translate>, <Translate>неправильне ім'я</Translate></p>}
                            <div style={{display: "flex", alignItems: "baseline"}}>
                                <span style={{marginRight: "6px"}}><Translate>Адреса</Translate></span>
                                <div style={{width: "100%"}}>
                                    <Suspense fallback={<Loading />}> 
                                        <MapsSearchBox loadCallback={(ref) => inputRef.current = ref} changeCallback={handlePlaceChanged}/>
                                    </Suspense>
                                </div>
                            </div>
                            {error === 2 && <p style={{color: "red", textAlign: "left"}}><Translate>Помилка</Translate>, <Translate>адреса недійсна</Translate></p>}
                            {address && <p style={{fontStyle: "italic"}}>{address}</p>}
                            <div style={{maxWidth: "320px"}}>
                                <Suspense>
                                    <PhoneInput placeholder={translate("Номер телефону", dicLang)} value={phone}
                                      onChange={setPhone}/>
                                </Suspense>
                            {error === 1 && <p style={{color: "red", textAlign: "left"}}><Translate>Помилка</Translate>, <Translate>недійсний номер</Translate></p>}
                            </div>
                        </div>}
                        <Translate>Коментар</Translate> (<Translate>не обов'язково</Translate>)
                        <Form.Control as="textarea" rows={2} value={comment} maxLength="128" onChange={(e) => setComment(e.target.value) } />
                        <Button style={{marginTop: "8px"}} onClick={purchase} size="lg" className="cartBuyButton">
                          <Translate>Перейти до оформлення замовлення</Translate>
                        </Button>
                        </>: <>
                        <p><Translate>Ви повинні створити обліковий запис, аби мати можливість робити покупки на платформі</Translate>.</p>
                        <Button onClick={() => {
                            document.getElementById("menuCreateAccountButton").click();
                        }}><Translate>Створити акаунт</Translate></Button>
                        </>
                        }
                    </Col>
                </Row>
            </div>
        }
        <Footer/>
    </div> :
    <form ref={formRef} method="post" action="https://secure.wayforpay.com/pay" acceptCharset="utf-8">
      <input type="hidden" name="apiVersion" value="2"/>
      <input type="hidden" name="merchantAccount" value={purchaseData.merchant}/>
      <input type="hidden" name="merchantAuthType" value="SimpleSignature"/>
      <input type="hidden" name="merchantDomainName" value={purchaseData.domain}/>
      <input type="hidden" name="orderReference" value={purchaseData.orderId.toString()}/>
      <input type="hidden" name="orderDate" value={purchaseData.orderDate}/>
      <input type="hidden" name="amount" value={purchaseData.total.toString()}/>
      <input type="hidden" name="productPrice[]" value={purchaseData.total.toString()}/>
      <input type="hidden" name="currency" value="UAH"/>
      <input type="hidden" name="orderTimeout" value="49000"/>
      <input type="hidden" name="productName[]" value={purchaseData.orderName}/>
      <input type="hidden" name="productCount[]" value="1"/>
      <input type="hidden" name="defaultPaymentSystem" value="card"/>
      <input type="hidden" name="merchantSignature" value={purchaseData.hash}/>
      <input type="hidden" name="serviceUrl" value={purchaseData.service}/>
      <input type="hidden" name="returnUrl" value={purchaseData.afterPayment}/>
      <input type="hidden" name="clientEmail" value={purchaseData.email}/>
      {phone !== '' && <input type="hidden" name="clientPhone" value={phone}/>}
    </form>
}

export default Cart