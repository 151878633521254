import Button from "react-bootstrap/Button";
import Translate, { translate } from "../../AppContext/Translate";
import { lazy, Suspense, useContext, useState } from "react";
import { BiSolidEditAlt, BiSolidTrashAlt } from "react-icons/bi";
import { AppContext } from "../../AppContext/AppContext";
import { postAPICall } from "../../Components/APICall";
import { confirm } from "../../Components/ConfirmFunction";

const UploadPicture = lazy(() => import('../../Components/UploadPicture'));

function ImgMenu({onEdit=null, onDelete=null}) {
    return <div style={{position: "absolute", top: 2, right: 2}}>
        {onEdit !== null && <Button style={{marginRight: "2px"}} onClick={() => { onEdit(); }}><BiSolidEditAlt /></Button>}
        {onDelete !== null && <Button onClick={() => { onDelete(); }}><BiSolidTrashAlt /></Button>}
    </div>
}

function AddItemImageCard({itemId, school, index=-1, videoLink=null, addImage=null, addVideo=null, deleteImage=null, number}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [showChangePicture, setShowChangePicture] = useState(false);
    const [videoCode, setVideoCode] = useState(videoLink === null ? "" : videoLink);

    const onDeleteImage = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити зображення?', dicLang)})) {
            postAPICall('item/delPhoto', {userId: userId, token: token, school: school, item: itemId, index: index + 1}, (data) => {
                if ('error' in data && data.error === "none") deleteImage();
                else saveError();
            }, saveError);
        }
    }

    const onDeleteVideo = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити відео?', dicLang)})) {
            postAPICall('item/delVideo', {userId: userId, token: token, school: school, itemId: itemId}, (data) => {
                if ('error' in data && data.error === "none") addVideo(null);
                else saveError();
            }, saveError);
        }
    }

    const isValidYouTubeVideoCode = (code) => {
        const regex = /^[a-zA-Z0-9_-]{11}$/;
        return regex.test(code);
    }

    const saveError = (msg=null) => {
        alert(translate("Помилка", dicLang) + ": " + translate("Не вдалося зберегти зміни", dicLang));
    }
    const addVideoCode = () => {
        if (isValidYouTubeVideoCode(videoCode)) {
            postAPICall('item/addVideo', {userId: userId, token: token, school: school, itemId: itemId, videoCode: videoCode},
                (data) => {
                    if ('error' in data && data.error === "none") addVideo(videoCode);
                    else saveError();
                }, saveError);
        } else alert(translate("Невірний код відео Youtube", dicLang));
    }

    return <div className="itemImageCard">
        <Suspense>
            <UploadPicture show={showChangePicture} handleClose={()=>{ setShowChangePicture(false); }}
                width={720} height={498} title="Фото товару"
                apiFnc="item/addPhoto" forceUpdate={() => {
                    if (index < 0 && addImage !== null) addImage();
                }}
                params={{item: itemId, index: index, school: school}}/>
        </Suspense>
        {index < 0 ? <div style={{width: "90%", textAlign: "center"}}>
            <Button variant="success" onClick={() => { setShowChangePicture(true); }}><Translate>Додати зображення</Translate></Button>
            {videoLink === null && <>
                <p className="top8 bot8"><Translate>або</Translate></p>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%"}}>
                    <input type="text" value={videoCode} placeholder="Введіть код відео Youtube" style={{width: "100%"}}
                        onChange={(event) => { setVideoCode(event.target.value); }}/>
                    <Button className="top4" onClick={addVideoCode}><Translate>Додати відео</Translate></Button>
                </div>
            </>}
        </div> : (videoLink === null ? <div style={{width: "100%", height: "100%"}}>
            <img alt="" src={"/items/item" + itemId + "_" + (index + 1) + ".jpg?n=" + number}
                style={{width: "100%", height: "100%"}}
                onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "/items/noitem.jpg";
                }}/>
            <ImgMenu onDelete={onDeleteImage} />
        </div> : <div style={{width: "100%", height: "100%"}}>
            <iframe width="320px" height="221px" src={"https://www.youtube.com/embed/" + videoLink} title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            <ImgMenu onDelete={onDeleteVideo} />
        </div>)}
    </div>
}

export default AddItemImageCard;