import { useState } from "react";
import Translate from "../../AppContext/Translate";
import { FaRegComment } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover';
import { postAPICall } from "../../Components/APICall";
import WriteComment from "./WriteComment";

function PostReactionOrComment({post, userId, token, student, courseId, setExpanded, parentPost, parentUser=0, 
    showCommentButton=true, textColor="black"}) {
    const [showWriteComment, setShowWriteComment] = useState(false);

    const postReaction = (reactId) => {
        postAPICall('reaction/react', {reactId: reactId, groupId: student.group, postId: post, 
            parentUser: parentUser, userId: userId, token: token, courseId: courseId, parentPost: parentPost}, () => {});
        document.body.click();
    }
    const popover = (
        <Popover style={{ width: "340px", minWidth: "340px", maxWidth: "340px" }}>
            <Popover.Body>
            <div style={{display: "flex"}}>
                <div className="reactionOption" onClick={() => postReaction(1)}>
                    <img src="/icons/like.gif" alt="" width="40px" height="40px"/>
                    <Translate>Мені подобається</Translate>
                </div>
                <div id="2" className="reactionOption" onClick={() => postReaction(2)}>
                    <img src="/icons/love.gif" alt="" width="30px" height="40px"/>
                    <Translate>Я люблю</Translate>
                </div>
                <div id="3" className="reactionOption" onClick={() => postReaction(3)}>
                    <img src="/icons/interesting.gif" alt="" width="40px" height="40px"/>
                    <Translate>Я зацікавлений</Translate>
                </div>
                <div id="4" className="reactionOption" onClick={() => postReaction(4)}>
                    <img src="/icons/amazed.gif" alt="" width="56px" height="40px"/>
                    <Translate>Мене вражає</Translate>
                </div>
            </div>
            </Popover.Body>
        </Popover>
    );

    return student.plan === 1 ? <div className="postReaction">
        <OverlayTrigger rootClose trigger="click" placement="top" overlay={popover}>
            <button className={parentUser > 0 ? "postReactionButtonSmall": "postReactionButton"} style={{color: textColor}}>
                <BiLike/> <Translate>Реагувати</Translate>
            </button>
        </OverlayTrigger>
        {showCommentButton ? <>
            <button className={parentUser > 0 ? "postReactionButtonSmall": "postReactionButton"} style={{color: textColor}}
                onClick={() => { setExpanded(true); setShowWriteComment(true);}}>
                <FaRegComment/> <Translate>Коментувати</Translate>
            </button>
            <WriteComment show={showWriteComment} setShow={setShowWriteComment} groupId={student.group}
                parentId={post} parentUser={parentUser} courseId={courseId}/>
            </> : <div style={{width: "80px"}}/>
        }
    </div> : null
}

export default PostReactionOrComment