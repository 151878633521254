import { useEffect, useState, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { TbPackages } from "react-icons/tb";
import Translate, { translate } from '../../AppContext/Translate';
import { getAPICall, postAPICall } from '../../Components/APICall';
import CabEditPackage from './CabEditPackage';
import Button from "react-bootstrap/Button";
import { AppContext } from '../../AppContext/AppContext';

function CabEditCoursePackages({course, options, onToggle, activeKey}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        getAPICall('course/packages', {courseId: course, order: 0, active: 0}, (data) => {
            if (data) setPackages(data);
        });
    }, [course]);

    const forceUpdate = () => {
        getAPICall('course/packages', {courseId: course, order: 0, active: 0}, (data) => {
            if (data) setPackages(data);
        });
    }

    const setField = (id, field, value) => {
        const nextPkg = packages.map((pkg, i) => {
            if (pkg.id === id) {
              var copy = {...pkg};
              copy[field] = value;
              return copy;
            } else return pkg;
          });
          setPackages(nextPkg);
    }

    const createError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося створити пакет", dicLang) + ".");
    }

    const addPackage = () => {
        postAPICall('course/newPackage', {userId: userId, token: token, course: course}, (data) => {
            if (data && 'pkgId' in data) {
                var pkgs = [...packages, {id: data.pkgId, course: course, plan: 0, title: "",
                    options: "", price: 0, active: 0, daysToExpire: 365000}];
                setPackages(pkgs);
                alert(translate("Новий пакет створено вимкненим", dicLang) + ". " +
                    translate("Заповніть дані та активуйте їх, якщо вони правильні", dicLang) + ".");
            } else createError();
        }, createError);
    }

    return <Accordion.Item eventKey="4">
        <Accordion.Header onClick={() => onToggle("4")}>
            <TbPackages size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Пакети</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            {activeKey === "4" && <>
                {packages.map((pkg, i) => {
                    return <CabEditPackage key={i} pkg={pkg} setField={setField} forceUpdate={forceUpdate} options={options}/>
                })}
                <Button variant="primary" style={{marginTop: "6px"}} onClick={addPackage}>
                    <Translate>Створіть новий пакет</Translate></Button>
            </>}
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCoursePackages