import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getAPICall } from '../../Components/APICall';
import AddItemImageCard from './AddItemImageCard';

function AdditionalItemImages({ itemId, handleClose, itemName, school }) {
    const [numImages, setNumImages] = useState(0);
    const [videoLink, setVideoLink] = useState(null);
    const [number, setNumber] = useState(1);
    const MAX_IMAGES = 8;

    useEffect(() => {
        getAPICall('item/addPics', { itemId: itemId }, (data) => {
            if (data.length > 0) {
                setNumImages(data[0].numImages);
                setVideoLink(data[0].videolink ? data[0].videolink : null);
            }
        });
    }, [itemId]);

    const addImage = () => {
        setNumImages(prevNumImages => prevNumImages + 1);
        setNumber(prevNumber => prevNumber + 1);
    };

    const deleteImage = () => {
        setNumImages(prevNumImages => prevNumImages - 1);
        setNumber(prevNumber => prevNumber + 1);
    };

    const addVideo = (code) => {
        setVideoLink(code);
        setNumber(prevNumber => prevNumber + 1);
    };

    return <Modal fullscreen show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                {Array.from({ length: numImages }).map((_, index) => (
                    <AddItemImageCard key={index} itemId={itemId} index={index} school={school} deleteImage={deleteImage}
                        number={number}/>
                ))}
                {videoLink !== null && <AddItemImageCard index={0} itemId={itemId} videoLink={videoLink}
                    school={school} addVideo={addVideo} number={number}/> }
                {numImages < MAX_IMAGES &&
                    <AddItemImageCard itemId={itemId} videoLink={videoLink} school={school}
                        addImage={addImage} addVideo={addVideo} number={number}/>
                }
            </div>
        </Modal.Body>
    </Modal>
}

export default AdditionalItemImages;