import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import Translate from '../AppContext/Translate';

function SectionGiftCard() {
    const num_designs = 6;

    return <div style={{backgroundColor: "white", textAlign: "center", padding: "48px 24px 12px 24px"}}>
        <h1 style={{zIndex: 100, position: "relative"}}><Translate>Подаруйте Творчість</Translate></h1>
        <p className='bot24'><Translate> Здивуйте близьких ідеальним подарунком на будь-яке свято – оберіть сертифікат на творчі курси, які надихають і відкривають нові можливості!</Translate></p>
        <div style={{width: "80%", maxWidth: "380px", margin: "auto"}}>
            <Carousel data-bs-theme="dark">
                {[...Array(num_designs).keys()].map((i) => (
                    <Carousel.Item key={i}>
                        <Link to={"/giftcard/" + (i+1)}>
                        <img
                            className="d-block w-100" style={{cursor: "pointer"}}
                            src={"/images/giftcard" + (i+1) + ".png"}
                            alt={`Gift card ${i+1}`}
                        />
                        </Link>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    </div>
}

export default SectionGiftCard