import { Navigate, useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import MagBanner from "../Components/MagBanner";
import HomeButton from "../Components/HomeButton";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import GiftPackage from "./GiftPackage";
import PurchaseGift from "./PurchaseGift";

function GiftCourse() {
    const [info, setInfo] = useState(null);
    const [packages, setPackages] = useState([]);
    const [options, setOptions] = useState({});
    const [pkgToPurchase, setPkgToPurchase] = useState(null);
    var params = useParams()["id"];
    const sparams = params ? params.split("-") : [];
    const design = sparams.length > 0 ? sparams[0] : 1;
    const courseId = sparams.length > 1 ? sparams[1] : 0;

    useEffect(() => {
        if (courseId > 0) {
            getAPICall('course/info', {course: courseId}, (data) => {
                if (data && data.length > 0) {
                    setInfo(data[0]);
                }
            });
            getAPICall('course/packages', {courseId: courseId, order: 1, active: 1}, (data) => {
                if (data && data.length > 0) setPackages(data);
            });
            getAPICall('course/options', {course: courseId}, async (data) => {
                if (data) {
                    var opt = {};
                    for (var i = 0; i < data.length; i++) {
                        const row = data[i];
                        opt[row.code] = row;
                    }
                    setOptions(opt);
                }
            });
        }
    }, [courseId]);

    return courseId <= 0 ? <Navigate to="/" replace={true} /> :
     <div className='mainContainer' style={{backgroundColor: "bisque"}}>
        <Menu/>
        <MagBanner title="Подаруйте Творчість" icon="/icons/gift.png" bkgColor="#fef5de"/>
        <div style={{padding: pkgToPurchase ? "24px 0px" : "24px"}}>
            {!pkgToPurchase ? <>
                {info && <h2>{info.title}</h2>}
                <p className="bold large bot24"><Translate>Виберіть пакет курсу, який ви хочете подарувати:</Translate></p>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start"}}>
                    {info && packages.map((pkg, i) => {
                        return <GiftPackage key={i} index={i} data={pkg} options={options}
                            numDays={info.numDays} setPkgToPurchase={setPkgToPurchase}/>
                    })}
                </div>
            </> : <PurchaseGift pkg={pkgToPurchase} title={info.title} design={design}/>}
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default GiftCourse