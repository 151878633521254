import { Navigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import BeatLoader from 'react-spinners/BeatLoader';
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import CourseInfo from "./CourseInfo";
import CourseContent from "./CourseContent";

export function convert2int(text) {
    var n = parseInt(text);
    return isNaN(n) ? -1 : n;
}

function parseParameter(pageParams) {
    const splittedParams = pageParams !== undefined && pageParams !== null && pageParams.length >= 1 ?  
        pageParams.split('-') : null;
    var taskId = -1;
    var postId = -1;
    var commentId = [];
    var courseId = -1;
    var topicId = -1;
    var group = -1;
    var tab = '';
    if (splittedParams) {
        courseId = convert2int(splittedParams[0]);
        for (var i = 1; i < splittedParams.length; i++) {
            const param = splittedParams[i];
            const letter = param.charAt(0); 
            if (letter === 't') {           // Task
                taskId = convert2int(param.substring(1));
                tab = 't';
            } else if (letter === 'p') {    // Post 
                postId = convert2int(param.substring(1));
            } else if (letter === 'c') {    // Comment
                commentId.push(convert2int(param.substring(1)));
            } else if (letter === 'd') {    // Topic (day) 
                topicId = convert2int(param.substring(1));
            } else if (letter === 'g') {    // Class group 
                group = convert2int(param.substring(1));
            } else if (letter === 'b') {    // Tab (p: plans, d: description, c: content, t: tasks, j: projects, o: options)
                tab = param.substring(1);
            }
        }
    }
    return {courseId: courseId, taskId: taskId, topicId: topicId, postId: postId, commentId: commentId,
        group: group, tab: tab}
}

function Course() {
    const initialParams = parseParameter(useParams()["id"]);
    const { userId, token } = useContext(AppContext);
    const [params, setParams] = useState(initialParams);
    const [courseInfo, setCourseInfo] = useState(null);
    const [teacher, setTeacher] = useState(false);
    const [student, setStudent] = useState([]);
    const [error, setError] = useState(initialParams.courseId < 0);
    
    useEffect(() => {
        const checkIsStudent = () => {
            getAPICall('students/group', {userId: userId, token: encodeURIComponent(token), course: params.courseId},
                (data) => {
                    if (data) {
                        setStudent(data);
                    }
                });
        }

        const getCourseData = (isTeacher) => {
            getAPICall('course/data', {course: params.courseId}, (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    if (row.active !== 1 && !isTeacher) {
                        setError(true);
                    } else { 
                        setCourseInfo(row);
                        if (userId >= 0) checkIsStudent();
                            else setStudent({student: false, dynamic: false, group: -1, options: ''});
                    }
                }
            }, () => { 
                setError(true);
            });
        }

        if (params.courseId > 0) {
            if (userId >= 0) {
                getAPICall('course/canEdit', {userId: userId, token: encodeURIComponent(token),
                    course: params.courseId, lowLevel: 1}, (data) => {
                        var isTeacher = false;
                        if ('allowed' in data && data.allowed === 1) {
                            isTeacher = true;
                            setTeacher(true);
                        }
                        getCourseData(isTeacher);
                    }, () => { getCourseData(false); });
            } else getCourseData(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token]);

    

    return error ? <Navigate to="/" replace={true} /> : 
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        {courseInfo ? (
            (student.length > 0 || teacher) ? 
            <CourseContent  info={courseInfo} student={student} teacher={teacher} params={params} setParams={setParams}/> 
            : <CourseInfo info={courseInfo} student={student} showMenu={true} goToPlans={params.tab === 'p'}/>
        ) : <BeatLoader size={28} color="#0d6efd"/>}
        <Footer/>
    </div>
}

export default Course