import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

function ScrollDots({selectedImage, setSelectedImage, numImages, videoLink}) {
    const totalDots = numImages + (videoLink !== null ? 1 : 0);
    return <div style={{ height: "22px", display: "flex", justifyContent: "space-between", width: "100%" }} className="top8 bot16">
        <FaAngleLeft  size="22" color={selectedImage > 0 ? "black" : " gray"} onClick={() => {
            if (selectedImage > 0)
                setSelectedImage(selectedImage - 1);
        }}/>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <GoDotFill size="22" style={{ color: selectedImage === 0 ? 'black' : 'gray', cursor: "pointer" }}
                onClick={() => { setSelectedImage(0); }} />
            {Array.from({ length: numImages }).map((_, index) => (
                <GoDotFill key={index} size="22" style={{
                    color: selectedImage === index + 1 ? 'black' : 'gray',
                    cursor: "pointer"
                }} onClick={() => { setSelectedImage(index + 1); }} />
            ))}
            {videoLink !== null &&
                <GoDotFill size="22" style={{
                    color: selectedImage === numImages + 1 ? 'black' : 'gray',
                    cursor: "pointer"
                }} onClick={() => { setSelectedImage(numImages + 1); }} />
            }
        </div>
        <FaAngleRight size="22" color={selectedImage < totalDots ? "black" : " gray"} onClick={() => {
            if (selectedImage < totalDots)
                setSelectedImage(selectedImage + 1);
        }}/>
    </div>
}

export default ScrollDots;