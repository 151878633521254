import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../../Components/APICall";
import { ChooseGroup } from "../CourseMaterials";
import BeatLoader from "react-spinners/BeatLoader";
import { AppContext } from "../../AppContext/AppContext";
import ChatComment from "../Chat/ChatComment";
import WriteComment from "../Chat/WriteComment";
import Translate from "../../AppContext/Translate";
import { FaRegComment } from "react-icons/fa6";
import UserPhotoIcon from "../../Components/UserPhotoIcon";

function GroupChat({course, student, teacher, params, setParams}) {
    const { userId, token, lastNotification } = useContext(AppContext);
    const [selectedGroup, setSelectedGroup] = useState(student.length > 0 && student[0].classgroup > 0 ? 
        student[0].classgroup : (params.group > 0 ? params.group : -1));
    const [selectedPkg, setSelectedPkg] = useState(student.length > 0 ? student[0].package : -1);
    const [comments, setComments] = useState([]);
    const [showWriteComment, setShowWriteComment] = useState(false);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (teacher) {
            getAPICall('course/packages', {courseId: course.id, order: 0, active: 1}, (data) => {
                if (data) {
                    var selPkg = -1;
                    for (var i = 0; i < data.length; i++) {
                        const pkg = data[i];
                        if (pkg.plan === 1) {
                            selPkg = pkg.id;
                            break;
                        }
                    }
                    setSelectedPkg(selPkg);
                }
            });
        } else if (student.length > 0) {
            setSelectedPkg(student[0].package);
        }
    }, [course.id, student, teacher]);

    const commentsSuccess = (data) => {
        if (data.length === 0 || (data.length > 0 && !("error" in data[0]))) {
            setComments(data);
        }
    }
    
    useEffect(() => {
        if (selectedGroup && selectedGroup > 0) {
            getAPICall('comments/list', {userId: userId, token: encodeURIComponent(token),
                postId: -selectedGroup, onComment: 0, groupId: selectedGroup}, commentsSuccess);
            getAPICall('chat/participants', {userId: userId, token: encodeURIComponent(token),
                groupId: selectedGroup}, (data) => { setParticipants(data); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup, userId, token]);

    useEffect(() => {
            if (lastNotification !== null && lastNotification["event"] === "comment" &&
            lastNotification["parentPost"] === -selectedGroup && lastNotification["parentUser"] === 0 &&
            lastNotification["group"] === selectedGroup) {
                getAPICall('comments/list', {userId: userId, token: encodeURIComponent(token), 
                    postId: -selectedGroup, onComment: 0, groupId: selectedGroup}, commentsSuccess);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotification, selectedGroup]);

    const studentData = {id: userId, package: selectedPkg, plan: 1, options: "",
        group: selectedGroup, student: !teacher};

    return <>
        <h1 style={{padding: "24px"}}><Translate>Груповий чат</Translate></h1>
        {teacher && <ChooseGroup courseId={course.id} numDays={course.numDays}
            selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}/>}
        {participants.length > 0 && 
        <div style={{ padding: "12px 24px" }}>
            <details>
                <summary style={{ cursor: "pointer", fontWeight: "bold" }}>
                    <Translate>Учасники</Translate>
                </summary>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "12px" }}>
                    {participants.map((participant, index) => (
                        <div key={index} style={{ margin: "4px 8px", padding: "8px", border: "1px solid #ccc",
                            borderRadius: "8px", backgroundColor: participant.teacher === 1 ? "thistle" : "white" }}>
                            <UserPhotoIcon user={participant.user} userName={participant.name}/>
                            {participant.name}
                        </div>
                    ))}
                </div>
            </details>
        </div>
        }
        {selectedGroup > 0 && selectedPkg > 0 ? <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <div className="chatPane" style={{backgroundImage: "url('/bkg/chat.jpg')", width: "100%",
                maxWidth: "680px", padding: "12px 24px 32px 24px", margin: "24px 0px"}}>
                {comments.map((comment, i) => {
                    return <div key={i}>
                        <ChatComment student={studentData} index={i} comment={comment} postPath={[-selectedGroup]} 
                            last={[i === comments.length - 1]} allExpanded={true} highlightComment={null}
                            courseId={course.id} parentId={-selectedGroup} parentUser={0} drawLines={false}/>
                    </div>
                })}
                <div style={{textAlign: "center", marginTop: "32px"}}>
                    <button className="userLink bold" style={{padding: "6px 32px",
                        backgroundColor: "#fee6dd", borderRadius: "16px", border: "2px solid white"}}
                        onClick={() => { setShowWriteComment(true);}}>
                        <FaRegComment/> <Translate>Коментувати</Translate>
                    </button>
                </div>
            </div>
            <WriteComment show={showWriteComment} setShow={setShowWriteComment} groupId={selectedGroup}
                parentId={-selectedGroup} parentUser={0} courseId={course.id}/>
        </div> : <BeatLoader/>}
    </>
}

export default GroupChat