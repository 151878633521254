import PostReactionOrComment from "./PostReactionOrComment"
import PostReactions from "./PostReactions"
import Translate from "../../AppContext/Translate"
import BookmarkState from "../../Components/BookmarkState"
import { BiTask } from 'react-icons/bi';
import { AttentionSeeker } from "react-awesome-reveal";
import PostMessage from "../../Components/PostMessage";

function ChatTask({post, refreshData, userId, token, student, courseId, setExpanded, reactions, comments, 
    changeExpanded, highlightPost, highlightComment, pageParams, setParams, path}) {
    
    const elements = <div className="taskPost" style={{background: "url('/bkg/task.jpg')", backgroundRepeat: "repeat", 
        backgroundSize: "400px"}}>
        {student.student && student.student >= 0 &&
        <BookmarkState context="post" kind={post.state} data={post.post} refreshData={refreshData} course={courseId}/>}
        <div className="textPost" style={{textAlign: "center", color: "antiquewhite", padding: "4px"}}>
            <p className="boldPost" style={{margin: "0px"}}><BiTask/> <Translate>Завдання</Translate></p>
            <p style={{marginBottom: "4px", color: "white"}}>{post["taskName"]}</p>
            <div style={{textAlign: "left", padding: "4px", backgroundColor: "#0000004a", borderRadius: "8px"}}>
                <PostMessage msg={post["message"]} path={path} open={false} sep={false} textStyle="smallTextPost"/>
            </div>
        </div>
        {student.student && student.student >= 0 &&
        <div style={{display: "flex", padding: "8px", justifyContent: "center"}}>
            <p className="buttonGoTask" onClick={() => {
                setParams({...pageParams, tab: "t", taskId: post.post,
                    group: student && student.group ? student.group : -1});
            }}><Translate>Відкрийте завдання</Translate></p>
        </div>}
        {(reactions.length > 0 || comments.length > 0) && 
            <><hr style={{marginTop: "8px", marginBottom: "8px", color: "white"}}/>
            <PostReactions reactions={reactions} numComments={comments.length} changeExpanded={changeExpanded}
                postId={post["post"]} textColor="white" student={student}/></>}
        {student.plan === 1 && <hr style={{marginTop: "8px", marginBottom: "8px", color: "white"}}/>}
        <PostReactionOrComment post={post["post"]} userId={userId} token={token} student={student}
            setExpanded={setExpanded} textColor="white" courseId={courseId} parentPost={0}/>
    </div>

    const bounce = (ref) => {
        if (ref && highlightPost === post.post && !highlightComment) {
            ref.scrollIntoView();
        }
    }

    return <div ref={ref => { bounce(ref); }}>
    { highlightPost === post.post && !highlightComment ?
      <AttentionSeeker effect="bounce">
        {elements}
      </AttentionSeeker> : 
      <>
        {elements}
      </> }
    </div>
}

export default ChatTask
