import Menu from "../Menu/Menu";
import SectionTitle from "./SectionTitle.js";
import SectionCategories from "./SectionCategories.js";
import SectionFormat from "./SectionFormat.js";
import SectionCourses from "./SectionCourses.js";
import SectionShop from "./SectionShop.js";
import Footer from "../Components/Footer.js";
import { useEffect, useRef, useState } from "react";
import SectionOpinions from "./SectionOpinions.js";
import SectionProjects from "./SectionProjects.js";
import SectionFaq from "./SectionFaq.js";
import VideoPreview from "../Components/VideoPreview.js";
import SectionGiftCard from "./SectionGiftCard.js";

function Home({login=false}) {
    const [video, setVideo] = useState(null);
    const coursesRef = useRef(null);

    useEffect(() => {
        if (login) {
            const button = document.getElementById("menuCreateAccountButton");
            if (button) {
                button.click();
            }
        }
    }, [login]); 

    return <div className='mainContainer'>
        {video && <VideoPreview video={video.id} setVideo={setVideo} title={video.title}/>}
        <Menu/>
        <SectionTitle setVideo={setVideo}/>
        <SectionCategories/>
        <SectionFormat coursesRef={coursesRef}/>
        <SectionCourses coursesRef={coursesRef}/>
        <SectionShop/>
        <SectionOpinions setVideo={setVideo}/>
        <SectionProjects/>
        <SectionGiftCard/>
        <SectionFaq/>
        <Footer/>
    </div>
}

export default Home