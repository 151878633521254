import { useContext, useEffect, useState } from "react";
import Footer from "../Components/Footer"
import MagBanner from "../Components/MagBanner"
import Menu from "../Menu/Menu"
import { AppContext } from "../AppContext/AppContext";
import Translate, { translate, translateWithNumber } from "../AppContext/Translate";
import MagButton from "../Components/MagButton";
import BeatLoader from "react-spinners/BeatLoader";
import { getAPICall, postAPICall } from "../Components/APICall";
import HomeButton from "../Components/HomeButton";
import ShowCourseGift from "./ShowCourseGift";
import StartDates from "../Course/StartDates";
import { Link } from "react-router-dom";

function GetGift() {
    const { userId, token, dicLang } = useContext(AppContext);
    const [code, setCode] = useState("");
    const [checking, setChecking] = useState(false);
    const [waitTime, setWaitTime] = useState(0);
    const [groupId, setGroupId] = useState(-1);
    const [dynamicPkg, setDynamicPkg] = useState(null);
    const [error, setError] = useState(null);

    const codeError = (res) => {
        setError(translate("Помилка", dicLang) + ": " + translate('введений код недійсний або вже використаний.', dicLang));
        if ('error' in res && typeof res.error === 'number') {
            setWaitTime(res.error);
        }
        setChecking(false);
    }

    const checkCode = (code) => {
        setError(null);
        setChecking(true);
        postAPICall('gift/apply', {userId: userId, token: token, code: code, groupId: groupId}, (res) => {
            if ('error' in res) {
                if (res.error === 'group') {
                    getAPICall('group/start', { courseId: res.course }, (datag) => {
                        if (datag) {
                            var dates = [];
                            for (var i = 0; i < datag.length; i++) {
                                const row = datag[i];
                                if (row.elapsedDays < row.numDays - 1) {
                                    dates.unshift(row);
                                } else break;
                            }
                            if (dates.length > 0) setGroupId(dates[0].id);
                            setDynamicPkg({...res, dates: dates});
                        }
                    });
                    setChecking(false);
                } else if (res.error === 'none') {
                    setChecking(false);
                    setDynamicPkg({...res, success: 1});
                } else {
                    codeError(res);
                }
            } else codeError(res);
        }, (res) => codeError({error: 0}));
    }

    const buttonEnabled = !checking && code.length === 16 && waitTime === 0;

    useEffect(() => {
        if (waitTime > 0) {
            const timer = setInterval(() => {
                setWaitTime(prevWaitTime => {
                    if (prevWaitTime <= 1) {
                        clearInterval(timer); // Limpiar el intervalo cuando llega a 0
                        return 0;
                    }
                    return prevWaitTime - 1;
                });
            }, 1000);
    
            // Limpia el intervalo si waitTime cambia antes de que termine el temporizador
            return () => clearInterval(timer);
        }
    }, [waitTime]);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Отримати свій подарунок" icon="/icons/gift.png" bkgColor="#fef5de"/>
        <div className="pad24">
            <h2><Translate>Ви отримали подарунок?</Translate></h2>
            <p className="large bold bot24"><Translate>Забирайте його тут!</Translate></p>
            {userId <= 0 ? <>
                <p className="bot16"><Translate>Але спочатку ви повинні увійти або створити обліковий запис на нашій платформі.</Translate></p>
                <MagButton text="Створити акаунт" clickEff={{customFnc: () => {
                    const menuCreateAccountButton = document.getElementById('menuCreateAccountButton');
                    if (menuCreateAccountButton) menuCreateAccountButton.click(); 
                }}}/>
            </> : <>
                <p className="bot16"><Translate>Введіть код, який ви отримали на подарунковому сертифікаті</Translate>:</p>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <input type="text" className="form-control fprag bold" placeholder="Код подарунку" maxLength={16} 
                        style={{fontSize: "28px", width: "100%", maxWidth: "380px", textAlign: "center", letterSpacing: "2px"}}
                        text={code} onChange={(e) => { setCode(e.target.value); }} />
                    {!dynamicPkg && <>
                        <MagButton text="Отримати" bkgColor={buttonEnabled ? "#f82424" : "#d3d3d3"}
                            addStyle={{cursor: buttonEnabled ? "pointer" : "default"}}
                            clickEff={{customFnc: () => { 
                                if (buttonEnabled) checkCode(code);
                            }}}/>
                        {error && <p className="top8" style={{color: "red"}}>{error}</p>}
                    </>}
                </div>
                {waitTime > 0 && <p className="top8">{translateWithNumber('Забагато неправильних спроб. Зачекайте * секунд, перш ніж повторити спробу.',
                    waitTime, dicLang)}</p>}
                {checking && <BeatLoader/>}
                {dynamicPkg && <div>
                    <p className="xlarge"><Translate>Це ваш подарунок!</Translate></p>
                    <ShowCourseGift course={dynamicPkg.course} courseTitle={dynamicPkg.courseTitle} pkgName={dynamicPkg.pkgName} />
                    {'dates' in dynamicPkg && <>
                        <StartDates dates={dynamicPkg.dates} setGroupId={setGroupId} groupId={groupId} pkgId={dynamicPkg.package} />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <MagButton text="Вибрати групу" bkgColor={groupId > 0 ? "#f82424" : "#d3d3d3"}
                                addStyle={{cursor: groupId > 0 ? "pointer" : "default"}}
                                clickEff={{customFnc: () => { 
                                    if (groupId > 0) checkCode(code);
                                }}}/>
                        </div>
                    </>}
                </div>}
                {dynamicPkg && 'success' in dynamicPkg && <>
                    <p className="top18 bold large"><img alt="" src="/icons/check.png" width="48px" className="right18"/>
                    Дякуємо! Ваш подарунок успішно використано.</p>
                    <p className="top18 large italic"><Translate>Ми неймовірно раді, що ви стали частиною нашої спільноти!</Translate></p>
                    <p className="top8"><Translate>Ви можете отримати доступ до свого нового курсу через розділ меню</Translate> «<Link to="/user/courses">Мої курси</Link>».</p>
                    <div style={{height: "1.5px", width: "75%", backgroundColor: "black"}} className="top18"/>
                    <p className="top18 bot24"><Translate>Якщо у вас є будь-які запитання чи пропозиції, пишіть нам на</Translate> <a href="mailto:support@magistrika.com">support@magistrika.com</a></p>
                </>}
            </>}
            <div style={{height: "48px"}}/>
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default GetGift