import { Link } from "react-router-dom"
import Translate, { formatDate } from "../../AppContext/Translate"
import { Box, encodeOrderNumber } from "../../User/Orders/Order"
import InfoButton from "../../Components/InfoButton"
import OrderSubtotal from "./OrderSubtotal"
import { getAPIurl } from "../../Components/APICall"

function CourseOrder({data, platformPercentage, role, gift=false}) {
    const pic = gift ? getAPIurl('gift/card', {design: data.design, code: data.code}) : "/courses/cover" + data.course + ".jpg";
    return <>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <img alt="" src={pic} width="128px" className="right6"/>
                <Box title={<Translate>{gift ? "Подарунковий сертифікат": "Курс"}</Translate>} value={data.courseName}/>
            </div>
            <Box title={<Translate>Дата</Translate>} value={formatDate(data.orderDate)}/>
            <Box title={<Translate>Замовлення</Translate>} value={<div style={{display: "flex"}}>
                {encodeOrderNumber(data.id)}
                {data.comment && data.comment.length > 0 && <InfoButton text={data.comment} translateText={false}/>}
            </div>}/>
            <Box title={<Translate>Замовник</Translate>} value={<Link to={"/profile/" + data.client}>{data.clientName}</Link>}/>
            <Box title={<Translate>Пакет</Translate>} value={data.pkgName}/>
        </div>
        <OrderSubtotal data={data} platformPercentage={platformPercentage} role={role} gift={gift}/>
        <hr/>
    </>
}

export default CourseOrder