import Table from 'react-bootstrap/Table';
import Translate from "../AppContext/Translate"
import Carousel from 'react-bootstrap/Carousel';
import { useContext, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AppContext } from '../AppContext/AppContext';
import Button from "react-bootstrap/Button";
import WriteHelper from '../Components/WriteHelper';
import { postAPICall } from '../Components/APICall';

function PurchaseGift({pkg, title, design}) {
    const { userId, token } = useContext(AppContext);
    const [cardDesign, setCardDesign] = useState(design);
    const [comment, setComment] = useState('');
    const [purchaseData, setPurchaseData] = useState(null);
    const formRef = useRef(null);
    const num_designs = 6;

    const purchase = () => {
        postAPICall('orders/gifthash', {userId: userId, token: token, total: pkg.price, pkg: pkg.id,
            design: cardDesign, course: pkg.course, comment: WriteHelper.filterSimpleText(comment)}, (data) => {
            setPurchaseData(data); 
        });
    }

    useEffect(() => {
        if (formRef && purchaseData) {
            formRef.current.submit();
        }
    }, [formRef, purchaseData]);

    return !purchaseData ? <>
        <h2 className="top24">
            <img alt="" src="/icons/shop.png" style={{width: "42px", verticalAlign: "text-bottom",
                marginRight: "8px", marginLeft: "24px"}}/>
            <Translate>Мій кошик</Translate></h2>
        <div className="cartItemDiv">
            <Table striped borderless hover>
                <thead>
                    <tr className="cartTableHeader">
                        <th colSpan="3" style={{color: "inherit", backgroundColor: "inherit", textAlign: "center"}}>
                            <Translate>Продукт</Translate></th>
                        <th style={{color: "inherit", backgroundColor: "inherit", textAlign: "center"}}>
                            <Translate>Ціна</Translate></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{verticalAlign: "middle"}} className="preventSelect">
                        <td colSpan={3} style={{textAlign: "center"}}>
                            <img className="cartPlanImage" alt="" src={"/courses/cover" + pkg.course + ".jpg"}/>
                            <div>
                                <div className="cartItemTitle">{title}</div>
                                <p className="cartItemSubtitle">{pkg.title}</p>
                            </div>
                        </td>
                        <td style={{textAlign: "center"}}>
                            <p className="cartItemPrice">{pkg.price.toFixed(2)} грн</p>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <p className='large bold'><Translate>Виберіть дизайн картки</Translate>:</p>
            <div style={{width: "80%", maxWidth: "380px", margin: "auto"}}>
                <Carousel data-bs-theme="dark" slide={false} activeIndex={cardDesign - 1}
                    interval={null}
                    onSelect={(index) => {
                        if (cardDesign !== index + 1) setCardDesign(index + 1);
                    }}>
                    {[...Array(num_designs).keys()].map((i) => (
                        <Carousel.Item key={i}>
                            <img
                                className="d-block w-100" style={{cursor: "pointer"}}
                                src={"/images/giftcard" + (i+1) + ".png"}
                                alt={`Gift card ${i+1}`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <Translate>Коментар</Translate> (<Translate>не обов'язково</Translate>)
            <Form.Control as="textarea" rows={2} value={comment} maxLength="128" onChange={(e) => setComment(e.target.value) } />
            <div className="top8 bot24">
                { userId >= 0 ? 
                    <Button onClick={purchase} size="lg" className="cartBuyButton">
                        <Translate>Перейти до оформлення замовлення</Translate>
                    </Button>
                : <>
                    <p><Translate>Ви повинні створити обліковий запис, аби мати можливість робити покупки на платформі</Translate>.</p>
                    <Button onClick={() => {
                        document.getElementById("menuCreateAccountButton").click();
                    }}><Translate>Створити акаунт</Translate></Button>
                </>}
            </div>
        </div>
    </> :
    <form ref={formRef} method="post" action="https://secure.wayforpay.com/pay" acceptCharset="utf-8">
      <input type="hidden" name="apiVersion" value="2"/>
      <input type="hidden" name="merchantAccount" value={purchaseData.merchant}/>
      <input type="hidden" name="merchantAuthType" value="SimpleSignature"/>
      <input type="hidden" name="merchantDomainName" value={purchaseData.domain}/>
      <input type="hidden" name="orderReference" value={purchaseData.orderId.toString()}/>
      <input type="hidden" name="orderDate" value={purchaseData.orderDate}/>
      <input type="hidden" name="amount" value={purchaseData.total.toString()}/>
      <input type="hidden" name="productPrice[]" value={purchaseData.total.toString()}/>
      <input type="hidden" name="currency" value="UAH"/>
      <input type="hidden" name="orderTimeout" value="49000"/>
      <input type="hidden" name="productName[]" value={purchaseData.orderName}/>
      <input type="hidden" name="productCount[]" value="1"/>
      <input type="hidden" name="defaultPaymentSystem" value="card"/>
      <input type="hidden" name="merchantSignature" value={purchaseData.hash}/>
      <input type="hidden" name="serviceUrl" value={purchaseData.service}/>
      <input type="hidden" name="returnUrl" value={purchaseData.afterPayment}/>
      <input type="hidden" name="clientEmail" value={purchaseData.email}/>
    </form>
}

export default PurchaseGift