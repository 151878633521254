import Navbar from 'react-bootstrap/Navbar';
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Translate, { chooseRowByLang, translateWithNumber } from '../AppContext/Translate';
import { getAPICall, postAPICall } from '../Components/APICall';
import InfiniteScroll from 'react-infinite-scroll-component';
import SyncLoader from 'react-spinners/SyncLoader';
import UserPhotoIcon from '../Components/UserPhotoIcon';
import SchoolPhotoIcon from '../Components/SchoolPhotoIcon';
import { Navigate } from 'react-router-dom';
import pathToLink from './PathToLink';
import { encodeOrderNumber } from '../User/Orders/Order';

const NOTIF_PERSONAL_MESSAGE = 1;   // New personal chat message
const NOTIF_COMMENT = 2;            // New comment to your comment
const NOTIF_REACTION = 3;           // New reaction to your comment
const NOTIF_OPEN_DAY = 4;           // New day available
const NOTIF_OPEN_TASK = 5;          // New task available
const NOTIF_REVIEW_TASK = 6;        // New teacher review on your task
const NOTIF_NEW_COURSE = 7;         // New course created
const NOTIF_MAT_COMMENT = 8;        // New comment to a course material
const NOTIF_MAT_REACTION = 9;       // New reaction to a course material
const NOTIF_NEW_SCHOOL = 10;        // New school created
const NOTIF_SCHOOL_ACCEPTED = 11;   // New school approved
const NOTIF_SCHOOL_REJECTED = 12;   // New school rejected
const NOTIF_COURSE_ACTIV_REQ = 13;  // Course activation request
const NOTIF_COURSE_ACTIVATED = 14;  // Course activated
const NOTIF_COURSE_REJECTED = 15;   // Course rejected
const NOTIF_TASK_SENT = 16;         // Task sent by student
const NOTIF_TASK_UPDATED = 17;      // Task updated by student
const NOTIF_COURSE_APPROVED = 18;   // Student passed the course
const NOTIF_COURSE_PURCHASED = 19;  // Student purchased a course
const NOTIF_ITEM_PURCHASED = 20;    // Student purchased an item
const NOTIF_ORDER_STATUS_CHANGE = 21;   // Order status change
const NOTIF_PAYMENT_REQUEST = 22;   // Payment request
const NOTIF_PAYMENT_DONE = 23;      // Payment done
const NOTIF_OPTION_SENT = 24;       // Course option sent to the student
const NOTIF_OPTIONREQ_SENT = 25;    // Course option request sent to the teacher

function NotificacionesHeader({markAllAsRead}) {
    return <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <div>
            <p className='notificationsTitle'><Translate>Ваші повідомлення</Translate></p>
        </div>
        <div>
            <p className='notificationsLink' onClick={()=>{markAllAsRead()}}><Translate>Позначити все як прочитане</Translate></p>
        </div>
    </div>
}

function NotificationsEnd() {
    return <p className='notificationText' style={{textAlign: 'center', marginTop: "4px", fontStyle: "italic"}}>
    <Translate>Більше повідомлень немає</Translate>
    </p>
}

const findCourse = (courseId, courses) => {
    return courses.find((element) => {
        return element.course === courseId;
    })
}

export const timeAgo = (prevDate, currentDate, dicLang) => {
    const diff =  new Date(currentDate).getTime() - new Date(prevDate).getTime();
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
        case diff < minute:
            const seconds = Math.round(diff / 1000);
            return translateWithNumber(seconds > 1 ? "* сек. тому" : "* секунду тому", seconds, dicLang);
        case diff < hour:
            const minutes = Math.round(diff / minute);
            return translateWithNumber(minutes > 1 ? "* хв. тому" : "* хвилину тому", minutes, dicLang);
        case diff < day:
            const hours = Math.round(diff / hour); 
            return translateWithNumber(hours > 1 ? "* год. тому" : "* годину тому", hours, dicLang);
        case diff < month:
            const days = Math.round(diff / day); 
            return translateWithNumber(days > 1 ? "* дн. тому" : "* день тому", days, dicLang);
        case diff < year:
            const months = Math.round(diff / month);
            return translateWithNumber(months > 1 ? "* міс. тому" : "* місяць тому", months, dicLang);
        case diff > year:
            const years = Math.round(diff / year); 
            return translateWithNumber(years > 1 ? "* р. тому" : "* рік тому", years, dicLang);
        default:
            return "";
    }
};

function Notification({data, coursesInfo, ext}) {
    var {userId, token, dicLang, updateNotificationsCount} = useContext(AppContext);
    const [courseTitle, setCourseTitle] = useState(null);
    const [userName, setUserName] = useState("⁇"); 
    const [clicked, setClicked] = useState(false);
    const [link, setLink] = useState(null);

    const kind = data.kind;
    const fromUser = kind !== NOTIF_OPEN_DAY &&
        kind !== NOTIF_OPEN_TASK &&
        kind !== NOTIF_NEW_COURSE;
    
    useEffect(() => {
        const getNameSuccess = (data) => {
            if (data.length === 1) setUserName(data[0].name);
        }
        if (data.course && data.course >= 0) {
            const course = findCourse(data.course, coursesInfo);
            if (course !== undefined) setCourseTitle(course.title);
        }
        if (fromUser) {
            getAPICall('user/name', {user: data.fromUser}, getNameSuccess);
        }
    }, [data, coursesInfo, fromUser]);

    var msg;
    switch (kind) {
    case NOTIF_PERSONAL_MESSAGE:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>надіслав/-ла вам повідомлення</Translate>.</p>
        break;
    case NOTIF_COMMENT:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>відповів на ваш коментар</Translate>.</p>;
        break;
    case NOTIF_REACTION:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>відреагував на ваш коментар</Translate>.</p>;
        break;
    case NOTIF_OPEN_DAY:
        msg = <p className='notificationText'><Translate>Курс</Translate> <span className='notificationName'>{courseTitle}
            </span>: <Translate>нові матеріали вже у доступі</Translate>.</p>
        break;
    case NOTIF_OPEN_TASK:
        msg = <p className='notificationText'><Translate>Курс</Translate> <span className='notificationName'>{courseTitle}
            </span>: <Translate>нове завдання вже у доступі</Translate>.</p>
        break;
    case NOTIF_REVIEW_TASK:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>перевірив/-ла ваше домашнє завдання</Translate>.</p>;
        break;
    case NOTIF_NEW_COURSE:
        msg = <p className='notificationText'><Translate>Опубліковано новий курс</Translate>.</p>
        break;
    case NOTIF_MAT_COMMENT:
        msg = <p className='notificationText'><span className='notificationName'>
            {userName}</span> <Translate>прокоментував матеріал курсу або в чаті</Translate>
            {courseTitle && <span><i> "{courseTitle}"</i></span>}
            .</p>;
        break;
    case NOTIF_MAT_REACTION:
        msg = <p className='notificationText'><span className='notificationName'>
            {userName}</span> <Translate>відреагував на матеріал курсу або в чаті</Translate>
            {courseTitle && <span><i> "{courseTitle}"</i></span>}
            .</p>;
        break;
    case NOTIF_NEW_SCHOOL:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>створив/-ла нову школу</Translate>.</p>;
        break;
    case NOTIF_SCHOOL_ACCEPTED:
        msg = <p className='notificationText'><Translate>Ваш запит на створення школи прийнято</Translate>.</p>;
        break;
    case NOTIF_SCHOOL_REJECTED:
        msg = <p className='notificationText'><Translate>Ваш запит на створення школи було відхилено адміністратором</Translate>.</p>;
        break;
    case NOTIF_COURSE_ACTIV_REQ:
        msg = <p className='notificationText'><Translate>Надійшов запит на активацію курсу</Translate>.</p>;
        break;
    case NOTIF_COURSE_ACTIVATED:
        msg = <p className='notificationText'><Translate>Адміністратор активував ваш курс</Translate>.</p>;
        break;
    case NOTIF_COURSE_REJECTED:
        msg = <p className='notificationText'><Translate>Адміністратор відхилив ваш курс</Translate>.</p>;
        break;
    case NOTIF_TASK_SENT:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>надіслав/-ла завдання</Translate>.</p>;
        break;
    case NOTIF_TASK_UPDATED:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>оновив/-ла завдання</Translate>.</p>;
        break;
    case NOTIF_COURSE_APPROVED:
        msg = <p className='notificationText'><Translate>Ви пройшли курс</Translate>
            {courseTitle && <span><i> "{courseTitle}"</i></span>}
            .</p>;
        break;
    case NOTIF_COURSE_PURCHASED:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>придбав/-ла курс</Translate>.</p>;
        break;
    case NOTIF_ITEM_PURCHASED:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>придбав/-ла товар</Translate>.</p>;
        break;
    case NOTIF_ORDER_STATUS_CHANGE:
        msg = <p className='notificationText'><Translate>Статус вашого замовлення змінився</Translate>: {encodeOrderNumber(data.msgId)}.</p>;
        break;
    case NOTIF_PAYMENT_REQUEST:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>ви запросили платіж</Translate>.</p>;
        break;
    case NOTIF_PAYMENT_DONE:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>ви здійснили платіж</Translate>: {data.msgId % 100}/{Math.floor(data.msgId/100)}.</p>;
        break;
    case NOTIF_OPTION_SENT:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>надав вам варіант курсу</Translate>.</p>;
        break;
    case NOTIF_OPTIONREQ_SENT:
        msg = <p className='notificationText'><span className='notificationName'>{userName}</span> <Translate>запросив виконання замовленої додаткової послуги</Translate>.</p>;
        break;
    default:
        msg = '';
    }
    
    const getPathSuccess = (data) => {
        if (data) {
            if (data.material < 0) setLink('/load/course/' + data.course.toString() + '-g' + data.group.toString() + '-bh');
            else setLink('/load/course/' + pathToLink(data));
        }
    }
    
    const notificationClick = () => {
        if (data.kind === NOTIF_PERSONAL_MESSAGE) {
            setLink('/load/user/messages');
        } else if (data.kind === NOTIF_NEW_SCHOOL || data.kind === NOTIF_COURSE_ACTIV_REQ) {
            setLink('/load/cabinet/admin');
        } else if (data.kind === NOTIF_SCHOOL_ACCEPTED || data.kind === NOTIF_SCHOOL_REJECTED) {
            setLink('/load/cabinet/school');
        } else if (data.kind === NOTIF_COURSE_ACTIVATED || data.kind === NOTIF_COURSE_REJECTED) {
            setLink('/load/cabinet/courses/' + data.course);
        } else if (data.kind === NOTIF_REVIEW_TASK) {
            getAPICall('task/post', {taskId: data.msgId}, (res) => {
                if (res && res.length > 0) setLink('/load/course/' + data.course + '-bt-t' + res[0].post);
                else setLink('/load/course/' + data.course + '-bt');
            }, () => { setLink('/load/course/' + data.course + '-bt'); });
        } else if (data.kind === NOTIF_COURSE_APPROVED) {
            setLink('/load/user/courses');
        } else if (data.kind === NOTIF_TASK_SENT || data.kind === NOTIF_TASK_UPDATED) {
            getAPICall('students/coursegroup', {user: data.fromUser, course: data.course},
                (res) => {
                    if (res && res.length > 0) {
                        setLink('/load/students/' + res[0].classgroup + '-s' + data.fromUser);
                    } else if (res) {
                        setLink('/load/static/' + data.course + '-s' + data.fromUser);
                    }
                });
        } else if (data.kind === NOTIF_COURSE_PURCHASED || data.kind === NOTIF_ITEM_PURCHASED) {
            setLink('/load/cabinet/report/director');
        } else if (data.kind === NOTIF_ORDER_STATUS_CHANGE) {
            setLink('/load/user/orders/' + data.msgId);
        } else if (data.kind === NOTIF_PAYMENT_REQUEST) {
            setLink('/load/cabinet/admin');
        } else if (data.kind === NOTIF_PAYMENT_DONE) {
            setLink('/load/cabinet/report');
        } else if (data.kind === NOTIF_NEW_COURSE) {
            setLink('/load/course/' + data.course);
        } else if (data.kind === NOTIF_OPTIONREQ_SENT) {
            setLink('/load/cabinet/students');
        } else if (data.kind === NOTIF_OPTION_SENT) {
            setLink('/load/course/' + data.course + '-bo');
        } else {
            var params = null;
            if (data.kind === NOTIF_COMMENT || data.kind === NOTIF_REACTION || data.kind === NOTIF_MAT_COMMENT) {
                params = {course: data.course, comment: data.msgId};
            } else if (data.kind === NOTIF_OPEN_DAY) {
                params = {course: data.course, topic: data.msgId};
            } else if (data.kind === NOTIF_OPEN_TASK || data.kind === NOTIF_MAT_REACTION) {
                params = {course: data.course, material: data.msgId};
            }
            if (params) {
                getAPICall('materials/path', params, getPathSuccess, () => {
                    setLink('error');
                });
            }
        }
        postAPICall('notifications/markAsRead', {userId: userId, token: token,
            msgId: data.msgId, kind: data.kind}, () => { updateNotificationsCount(); });
        setClicked(true);
    }

    return clicked ? (link ? (link === 'error' ? <div className={data.state === 0 ? "notificationsMsg" : "notificationsMsgRead"}
        style={{height: "48px", paddingTop: "12px", justifyContent: "center"}}>
            <Translate>Це посилання більше не доступне</Translate>.
        </div> : <Navigate to={link} replace={true}/>) :
    <div className={data.state === 0 ? "notificationsMsg" : "notificationsMsgRead"}
        style={{height: "48px", paddingTop: "12px", justifyContent: "center"}}>
        <SyncLoader size={18} color="peru"/>
    </div>
    ):
    <div className={data.state === 0 ? "notificationsMsg" : "notificationsMsgRead"}
        onClick={notificationClick}>
        <div style={{width: "36px"}}>
            { fromUser ? <UserPhotoIcon user={data.fromUser} userName={userName}/> :
              <SchoolPhotoIcon school={data.fromUser}/> }
        </div>
        <div style={{width: "100%", paddingLeft: "5px", paddingRight: "5px"}}>
            {msg}
            <p className="chatUserName">{timeAgo(data.msgDate, data.now, dicLang)}</p>
        </div>
        {data.course && data.course >= 0 && <div style={{width: "86px"}}>
            <img width="86px" height="48px" src={"/courses/cover" + data.course + ext} alt=""
                style={{borderRadius: "6px"}}/>
        </div> }
        {kind === NOTIF_ITEM_PURCHASED && <div style={{width: "86px"}}>
        <img width="86px" height="48px" src={"/items/item" + data.msgId + ext} alt=""
            style={{borderRadius: "6px"}}/>
    </div> }
    </div>
}

function NotificacionesPane() {
    var {userId, token, lang} = useContext(AppContext);
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const [coursesInfo, setCoursesInfo] = useState([]);
    const ext = ".jpg";
    const limit = 20;

    const onGetCourseName = (data) => {
        const row = chooseRowByLang(data, lang);
        if (row !== null)
            setCoursesInfo(current => [...current, {course: row.course, title: row.title}]);
    }

    const getNotificationsSuccess = (data) => {
        if (data && data.length > 0) {
            var coursesCopy = [...coursesInfo];
            for (var i = 0; i < data.length; i++) {
                const courseId = data[i].course;
                if (courseId && courseId >= 0 && findCourse(courseId, coursesCopy) === undefined) {
                    coursesCopy.push({course: courseId});
                    getAPICall('courses/name', {courseId: courseId}, onGetCourseName);
                }
            }
            setState({
                items: state.items.concat(data),
                hasMore: data.length >= limit,
                offset: state.offset + data.length
            });
        } else getNotificationsError(data);
    }

    const getNotificationsError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = () => {
        getAPICall('notifications/list', {userId: userId, token: encodeURIComponent(token),
            offset: state.offset, limit: limit},
            getNotificationsSuccess, getNotificationsError);
    }

    useEffect(() => {
        fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className='notificationsMsgsPane' id="notifScrollableDiv">
        {state.items.length === 0 ?
        <NotificationsEnd/>
        :
        <InfiniteScroll
            dataLength={state.items.length}
            next={fetchMoreData}
            hasMore={state.hasMore}
            loader={<p><SyncLoader size={18} color="#73694c"/></p>}
            scrollableTarget="notifScrollableDiv"
            endMessage={<NotificationsEnd/>}>
            {state.items.map((data, index) => 
                <Notification key={index} data={data} coursesInfo={coursesInfo} ext={ext}/>)}
        </InfiniteScroll> }
    </div>
}

function MenuNotifications() {
    var { userId, token, notifications, updateNotificationsCount } = useContext(AppContext);
    const markAllAsRead = () => {
        postAPICall('notifications/markAllAsRead', {userId: userId, token: token}, 
            () => { document.body.click(); updateNotificationsCount(); });
    }

    const notificationsPopover = (
        <Popover id="popover-notifications" style={{maxWidth: "800px"}}>
          <div className="notificationsPane">
            <NotificacionesHeader markAllAsRead={markAllAsRead}/>
            <NotificacionesPane/>
          </div>
        </Popover>);

    var imgSty = {};
    if (notifications <= 0) imgSty["filter"] = "brightness(50%)";

    return userId >= 0 ?
        <Navbar.Text>
            <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={notificationsPopover}>
                <div className='menuDivIcon'>
                    <img alt="" src="/bkg/notif.png" height="26" style={imgSty}/>
                    {notifications > 0 && 
                        <p style={{margin: "0px", fontSize: "10px", color: "white"}}>{notifications}</p>}
                </div>
            </OverlayTrigger>
        </Navbar.Text>
        : <></>
}

export default MenuNotifications