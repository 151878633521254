import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import ReportSubtotal from "./ReportSubtotal";
import PaymentStatus from "./PaymentStatus";
import CourseOrder from "./CourseOrder";
import ItemOrder from "./ItemOrder";

function SchoolReport({schoolId, lines}) {
    const [schoolName, setSchoolName] = useState('');

    var benefit = 0;
    if (schoolId in lines.courses) {
        const courses = lines.courses[schoolId];
        for (var i = 0; i < courses.length; i++) benefit += courses[i].benefit;
    }
    if (schoolId in lines.items) {
        const items = lines.items[schoolId];
        for (var j = 0; j < items.length; j++) benefit += items[j].benefit;
    }
    if (schoolId in lines.gifts) { 
        const gifts = lines.gifts[schoolId];
        for (var k = 0; k < gifts.length; k++) benefit += gifts[k].benefit;
    }

    useEffect(() => {
        getAPICall('school/name', {school: schoolId}, (data) => {
            if (data && 'name' in data) setSchoolName(data.name);
        });
    }, [schoolId]);

    return <div style={{border: "solid black thin", borderRadius: "12px", margin: "24px 0px"}} className="pad12">
        <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>     
            <img className="smallSchoolLogo" style={{marginRight: "16px"}} 
                alt="Logo" src={'/schools/logo' + schoolId + '.png'}/>     
            <p className="large bold">{schoolName}</p>
        </div>
        <hr/>
        {schoolId in lines.courses && lines.courses[schoolId].map((order, i) => {
            return <CourseOrder key={i} data={order} platformPercentage={lines.platformPercentage}
                role="teacher" gift={false}/>
        })}
        {schoolId in lines.items && lines.items[schoolId].map((order, i) => {
            return <ItemOrder key={i} data={order} platformPercentage={lines.platformPercentage}
                role="teacher"/>
        })}
        {schoolId in lines.gifts && lines.gifts[schoolId].map((order, i) => {
            return <CourseOrder key={i} data={order} platformPercentage={lines.platformPercentage}
                role="teacher" gift={true}/>
        })}
        <ReportSubtotal total={benefit}/>
    </div>
}

function TeacherReport({month, year}) {
    const { userId, token } = useContext(AppContext);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(false);
    const [schools, setSchools] = useState([]);
    const [lines, setLines] = useState(null);

    useEffect(() => {
        setError(false);
        setTotal(0);
        getAPICall('orders/teacher', {userId: userId, token: encodeURIComponent(token),
            month: month + 1, year: year}, (data) => {
            if (data) {
                const courseOrdersBySchool = data.courses.reduce((acc, item) => {
                    if (!acc[item.school]) acc[item.school] = [];
                    acc[item.school].push(item);
                    return acc;
                }, {});
                const itemOrdersBySchool = data.items.reduce((acc, item) => {
                    if (!acc[item.school]) acc[item.school] = [];
                    acc[item.school].push(item);
                    return acc;
                }, {});
                const giftOrdersBySchool = data.gifts.reduce((acc, item) => {
                    if (!acc[item.school]) acc[item.school] = [];
                    acc[item.school].push(item);
                    return acc;
                }, {});
                const schools1 = Object.keys(courseOrdersBySchool);
                const schools2 = Object.keys(itemOrdersBySchool);
                const schools3 = Object.keys(giftOrdersBySchool);
                setSchools([...new Set([...schools1, ...schools2, ...schools3])]);
                setLines({courses: courseOrdersBySchool, items: itemOrdersBySchool, gifts: giftOrdersBySchool,
                    total: data.total, commission: data.commission, platformPercentage: data.platformPercentage});
                setTotal(data.total);
            }
        }, () => { setError(true); });
    }, [userId, token, month, year]);

    return schools.length === 0 ? <p><Translate>Інформація за цей місяць відсутня</Translate>.</p> :
    (error ? <p style={{color: "indianred"}}><Translate>Звіт за цей місяць ще не доступний, спробуйте пізніше.</Translate></p> :
    <>  {lines && schools.map((school, i) => {
            return <SchoolReport key={i} schoolId={school} lines={lines}/>
        })}
        <ReportSubtotal total={total} text="Всього за місяць"/>
        <PaymentStatus month={month + 1} year={year} total={total}/>
    </>)
}

export default TeacherReport
