import { getAPIurl } from "./APICall";
import AttFile from "./AttFile";
import { MimeIconSmall } from "./MimeIcon";
import axios from 'axios';

// b: bold, c: italic, u: underline, l: list, *: item, e: emoticon, a: link, i: image, f: file, v: video

const symbol_replace = {
    "|": "｜", "'": "’", "<b>": "|b", "</b>": "||b",
    "<i>": "|c", "</i>": "||c", "<u>": "|u", "</u>": "||u",
    "<ul>": "|l", "</ul>": "||l", "<li>": "|*", "</li>": "||*", "<a>": "|a", "</a>": "||a",
    "<div>": "|n", "</div>": "", "<br>": "|n", "<br/>": "|n", "<br />": "|n"
};

const symbol_replace_inv = {
    "|l|n": "|l", "||b": "</b>", "|b": "<b>", "||c": "</i>", "|c": "<i>",
    "||u": "</u>", "|u": "<u>", "||l": "</ul>", "|l": "<ul>", "||*": "</li>", "|*": "<li>",
    "|n": "<br/>", "\n": "<br/>"
};

class WriteHelper {

    static filterSimpleText = (text) => {
        if (text == null) return "";
        return text.replace(/\|/g, '｜').replace(/'/g, '’').replace(/§/g, '$');
    }

    static async parseMessage(text, path, userId = null, token = null) {
        const sym = Object.keys(symbol_replace_inv);
        var res = text;
        for (var i = 0; i < sym.length; i++) {
            res = res.replaceAll(sym[i], symbol_replace_inv[sym[i]]);
        }
        var files = [];
        var pos = res.indexOf('|');
        while (pos >= 0) {
            const code = res[pos + 1];
            var posEnd = res.indexOf('|', pos + 2);
            if (posEnd < pos) break;
            if (code === 'i' || code === 'f' || code === 'v') { // Image/file/video (name, size, buffer, ext)
                const name = res.substring(pos + 2, posEnd);
                if (code === 'i' || code === 'f') {
                    var params = { name: encodeURIComponent(name), path: encodeURIComponent(path.jsonPath) };
                    if (userId) params["userId"] = userId;
                    if (token) params["token"] = encodeURIComponent(token);
                    const url = getAPIurl('media/img', params);
                    files.push(new AttFile(code, name, { url: url }));
                } else {
                    const videoId = parseInt(name);
                    var url = getAPIurl('media/videoType', { name: videoId });
                    let kind;
                    try {
                        kind = await axios.get(url, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        });
                        kind = kind.data["kind"];
                    } catch (e) {
                        kind = 'video/mp4';
                    }
                    params = { name_i: videoId };
                    if (userId) params["userId_i"] = userId;
                    if (token) params["token_s"] = encodeURIComponent(token);
                    url = getAPIurl('media/hls', params) + '&kind=' + kind;
                    files.push(new AttFile(code, name, { url: url }));
                }
                res = res.substring(0, pos) + res.substring(posEnd + 1);
            } else if (code === 'e') {
                res = res.substring(0, pos) + '<img alt="" width="18px" height="18px" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/'
                    + res.substring(pos + 2, posEnd) + '.png"/>' + res.substring(posEnd + 1);
            } else if (code === 'a') {
                var posLast = res.indexOf('||a', posEnd + 1);
                if (posLast < posEnd) break;
                res = res.substring(0, pos) + '<a href="' + res.substring(pos + 2, posEnd) +
                    '">' + res.substring(posEnd + 1, posLast) + '</a>' + res.substring(posLast + 3);
            } else {
                //console.log(code);
                break;
            }
            pos = res.indexOf('|');
        }
        return { text: res, files: files };
    }

    static encode(text) {
        const sym = Object.keys(symbol_replace);
        var res = text;
        for (var i = 0; i < sym.length; i++) {
            res = res.replaceAll(sym[i], symbol_replace[sym[i]]);
        }
        var pos = res.indexOf('<img '), pos2;
        while (pos >= 0) {
            pos2 = res.indexOf('>', pos);
            var img = res.substring(pos, pos2 + 1);
            var png = img.lastIndexOf('.png');
            var bar = img.lastIndexOf('/', png);
            res = res.substring(0, pos) + '|e' + img.substring(bar + 1, png) + '|' +
                res.substring(pos2 + 1);
            pos = res.indexOf('<img ');
        }
        pos = res.indexOf('<a ');
        while (pos >= 0) {
            pos2 = res.indexOf('href', pos) + 6;
            var pos3 = res.indexOf('"', pos2 + 1);
            var pos4 = res.indexOf('>', pos3 + 1);
            var link = res.substring(pos2, pos3);
            res = res.substring(0, pos) + '|a' + link + '|' + res.substring(pos4 + 1);
            pos = res.indexOf('<a ');
        }
        return res;
    }

    static addAttachedFiles(text, files, rename = null) {
        for (var i = 0; i < files.length; i++) {
            const f = files[i];
            if (!f.deleted) {
                const name = rename ? rename(f) : f.name;
                text += '|' + f.kind + name + '|';
            }
        }
        return text;
    }

    static encodeWithFiles(item) {
        var text = WriteHelper.encode(WriteHelper.filterSimpleText(item.text));
        return WriteHelper.addAttachedFiles(text, item.files, (file) => {
            if (file.isVideo() && "id" in file && file.id >= 0) return file.id;
            return file.name;
        });
    }

    static parseImages(text, path, userId, token, images, onPreview) {
        var res = text;
        var pos = res.indexOf('|i');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            var params = { name: res.substring(pos + 2, pos2), path: encodeURIComponent(path.jsonPath) };
            if (userId) params["userId"] = userId;
            if (token) params["token"] = encodeURIComponent(token);
            var url = getAPIurl('media/img', params);
            (function (currentParams, currentUrl) {
                images.push(<div key={"i" + images.length} className="postimgdiv" style={{ cursor: onPreview ? 'pointer' : 'auto' }} >
                    <img className="postimg" alt="" src={currentUrl} onClick={() => { if (onPreview) onPreview({ kind: 'i', name: currentParams.name }); }} />
                </div>);
            })(params, url);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|i');
        }
        return res;
    }

    static parseFiles(text, files, onPreview) {
        var res = text;
        var pos = res.indexOf('|f');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            files.push(<div key={"f" + files.length} className="chatCommentImgDiv" style={{ margin: "4px" }}>
                <MimeIconSmall name={res.substring(pos + 2, pos2)} onPreview={onPreview} />
            </div>);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|f');
        }
        return res;
    }

    static parseVideos(text, userId, token, videos, onPreview) {
        var res = text;
        var pos = res.indexOf('|v');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            const videoId = parseInt(res.substring(pos + 2, pos2));
            const coverUrl = getAPIurl('media/videoCover', { name: videoId });
            videos.push(<div key={"v" + videos.length} className="postvideodiv">
                <img alt="відтворити відео" src={coverUrl} onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/bkg/videoplay.png";
                }} style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => { onPreview({ kind: 'm', name: videoId }); }} />
            </div>);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|v');
        }
        return res;
    }

    static parseTextToElements(input, st) {
        if (input.indexOf('|e') === -1 && input.indexOf('|a') === -1)
            return input
        const elements = [];
        let currentIndex = 0;

        while (currentIndex < input.length) {
            const emojiIndex = input.indexOf('|e', currentIndex);
            const linkIndex = input.indexOf('|a', currentIndex);

            // Si no hay más patrones especiales, agregamos el resto del texto.
            if (emojiIndex === -1 && linkIndex === -1) {
                const remainingText = input.slice(currentIndex);
                if (remainingText) {
                    elements.push(<span key={elements.length}>{remainingText}</span>);
                }
                break;
            }

            // Determinar cuál patrón viene primero
            let nextIndex = -1;
            let patternType = null;
            if (emojiIndex !== -1 && (linkIndex === -1 || emojiIndex < linkIndex)) {
                nextIndex = emojiIndex;
                patternType = 'emoji';
            } else if (linkIndex !== -1 && (emojiIndex === -1 || linkIndex < emojiIndex)) {
                nextIndex = linkIndex;
                patternType = 'link';
            }

            // Agregar el texto anterior al patrón encontrado como <span>
            if (nextIndex > currentIndex) {
                const textSegment = input.slice(currentIndex, nextIndex);
                if (textSegment) {
                    elements.push(<span key={elements.length}>{textSegment}</span>);
                }
            }

            if (patternType === 'emoji') {
                // Patrón emoji: |eCÓDIGO|
                const start = nextIndex + 2; // después de "|e"
                const end = input.indexOf('|', start);
                if (end === -1) {
                    // No se encontró el cierre, se agrega como texto literal.
                    const textSegment = input.slice(nextIndex);
                    elements.push(<span key={elements.length}>{textSegment}</span>);
                    break;
                }
                const code = input.slice(start, end);

                elements.push(
                    <img
                        key={elements.length}
                        alt=""
                        width="18px"
                        height="18px"
                        src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${code}.png`}
                    />
                );

                currentIndex = end + 1; // continuar después del '|'
            } else if (patternType === 'link') {
                // Patrón link: |aURL|TEXTO||a
                const start = nextIndex + 2; // después de "|a"
                const urlEnd = input.indexOf('|', start);
                if (urlEnd === -1) {
                    // No cierra correctamente
                    const textSegment = input.slice(nextIndex);
                    elements.push(<span key={elements.length}>{textSegment}</span>);
                    break;
                }
                const url = input.slice(start, urlEnd);

                const textStart = urlEnd + 1;
                const textEnd = input.indexOf('||a', textStart);
                if (textEnd === -1) {
                    // No cierra correctamente el enlace
                    const textSegment = input.slice(nextIndex);
                    elements.push(<span key={elements.length}>{textSegment}</span>);
                    break;
                }
                const linkText = input.slice(textStart, textEnd);

                elements.push(
                    <a  className={st}
                        key={elements.length}
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {linkText}
                    </a>
                );

                currentIndex = textEnd + 3; // saltamos "||a"
            }
        }

        return elements;
    }

    static processInfo(info, elements, textStyle) {
        function noGap(s) {
            return /[^\u00A0]/.test(s);
        }
        const applyFormat = (content, format) => {
            let formattedContent = content;
            if (format.includes("b")) formattedContent = <strong>{formattedContent}</strong>;
            if (format.includes("c")) formattedContent = <em>{formattedContent}</em>;
            if (format.includes("u")) formattedContent = <u>{formattedContent}</u>;
            return formattedContent;
        };

        const data = [];
        info.forEach(([context, text, format], index) => {
            const parts = text.split("|n");
            parts.forEach((part, partIndex) => {
                const isLastInItem = partIndex === parts.length - 1;
                const isLastInArray = index === data.length - 1;
                const hasDifferentContext = isLastInItem && (isLastInArray || context !== data[index + 1]?.[0]);
                const continues = isLastInItem || hasDifferentContext;
                if (part !== "" || (!continues && data.length > 0 && data[data.length - 1][1] !== "")) {
                    data.push([context, part, format, continues]);
                }
            });
        });
        if (data.length > 0) data[data.length - 1][3] = false;
        let currentContext = 't';
        let items = [];
        let itemsDesc = '';
        let listItems = [];
        let keyIndex = 0;
        for (let i = 0; i < data.length; i++) {
            let [context, text, format, cont] = data[i];
            if (text.trim() === "") text = "\u00A0";
            const itemElements = WriteHelper.parseTextToElements(text, textStyle);
            const newItem = <span key={keyIndex++}>{applyFormat(itemElements, format)}</span>;
            if (cont) {
                if (currentContext === context) {
                    items.push(newItem);
                    itemsDesc += text;
                } else {
                    currentContext = context;
                    if (context === 'l') { // start list
                        if (items.length > 0) elements.push(<p key={keyIndex++} className={textStyle}>{items}</p>);
                        items = [newItem];
                        itemsDesc = text;
                        listItems = [];
                    } else { // End list
                        if (items.length > 0 && noGap(itemsDesc)) listItems.push(<li key={keyIndex++} className={textStyle}>{items}</li>);
                        if (listItems.length > 0) elements.push(<ul key={keyIndex++} className={textStyle}>{listItems}</ul>);
                        items = [newItem];
                        itemsDesc = text;
                        listItems = [];
                    }
                }
            } else {
                if (currentContext === context) {
                    items.push(newItem);
                    itemsDesc += text;
                    if (context === 't') {
                        elements.push(<p key={keyIndex++} className={textStyle}>{items}</p>);
                    } else {
                        if (noGap(itemsDesc)) listItems.push(<li key={keyIndex++}>{items}</li>);
                        if (i === data.length - 1 && listItems.length > 0)
                            elements.push(<ul key={keyIndex++} className={textStyle}>{listItems}</ul>);
                    }
                    items = [];
                    itemsDesc = '';
                } else {
                    currentContext = context;
                    if (context === 'l') { // start list
                        if (items.length > 0) elements.push(<p key={keyIndex++} className={textStyle}>{items}</p>);
                        items = [];
                        itemsDesc = '';
                        listItems = noGap(text) ? [<li key={keyIndex++} className={textStyle}>{newItem}</li>] : [];
                    } else { // End list
                        if (items.length > 0 && noGap(itemsDesc)) listItems.push(<li key={keyIndex++} className={textStyle}>{items}</li>);
                        if (listItems.length > 0) elements.push(<ul key={keyIndex++} className={textStyle}>{listItems}</ul>);
                        items = [];
                        itemsDesc = '';
                        elements.push(<p key={keyIndex++} className={textStyle}>{newItem}</p>);
                        listItems = [];
                    }
                }
            }
        }
    }

    static processText(text, elements, textStyle) {
        var info = [];
        var attr = '';
        var context = 't';
        var start = 0;
        text = text.replace(/\|\|\*/g, '|n').replace(/\|\*/g, '');
        var next = text.indexOf('|');
        while (next >= 0) {
            var c = next < text.length - 1 ? text[next + 1] : '';
            if (['a', 'e'].includes(c)) {
                if (c === 'a') next = text.indexOf('||a', next) + 2;
                else next = text.indexOf('|', next + 1);
            }
            if (['b', 'c', 'u', 'l', '|'].includes(c)) {
                if (next > start) {
                    info.push([context, text.substring(start, next).replace(/\n/g, '|n'), attr]);
                }
                if (c === 'b' || c === 'c' || c === 'u') {
                    if (!attr.includes(c)) attr += c;
                    start = next + 2;
                    next = text.indexOf('|', start);
                } else if (c === '|') {
                    c = next < text.length - 2 ? text[next + 2] : '';
                    if (c === 'l') context = 't';
                    else attr = attr.replace(new RegExp(c, 'g'), '');
                    start = next + 3;
                    next = text.indexOf('|', start);
                } else if (c === 'l') {
                    context = 'l';
                    start = next + 2;
                    next = text.indexOf('|', start);
                }
            } else {
                next = text.indexOf('|', next + 1);
            }
        }
        if (start < text.length - 1) {
            info.push([context, text.substring(start).replace(/\n/g, '|n'), attr]);
        }
        for (var i = 1; i < info.length; i++) {
            while (info[i][1].startsWith('|n')) {
                info[i - 1][1] += '|n';
                info[i][1] = info[i][1].substring(2);
            }
        }
        WriteHelper.processInfo(info, elements, textStyle);
    }

    static parseElements(text, path, userId = null, token = null, textStyle = "textPost", onPreview = null) {
        var elements = [];
        var images = [];
        var videos = [];
        var files = [];
        var res = WriteHelper.parseImages(text, path, userId, token, images, onPreview);
        res = WriteHelper.parseVideos(res, userId, token, videos, onPreview);
        res = WriteHelper.parseFiles(res, files, onPreview);
        var i;
        for (i = 0; i < videos.length; i++) elements.push(videos[i]);
        WriteHelper.processText(res, elements, textStyle);
        if (files.length > 0 && images.length > 0) {
            elements.push(<div key={"m" + elements.length} style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                {images}
                <div style={{ padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                    {files}
                </div>
            </div>)
        } else if (images.length > 0) {
            elements.push(<div key={"i" + elements.length} style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                {images}
            </div>)
        } else if (files.length > 0) {
            elements.push(<div key={"f" + elements.length} style={{ padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                {files}
            </div>)
        }
        return elements;
    }
}

export default WriteHelper