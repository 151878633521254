import Card from 'react-bootstrap/Card';
import Translate from '../../AppContext/Translate';

function ModalityCard({title, icon, onSelect}) {
    return <Card style={{ width: '18rem', border: "none", padding: "12px", textAlign: "center", cursor: "pointer"}}
        onClick={onSelect}>
        <Card.Img variant="top" src={"/icons/" + icon + ".png"} alt={icon}
                style={{width: "148px", border: "solid black", margin: "auto", padding: "16px", borderRadius: "16px"}} />
        <Card.Body style={{paddingTop: "8px"}}>
            <Card.Title className='fkenyan'><Translate>{title}</Translate></Card.Title>
        </Card.Body>
    </Card>
}

export default ModalityCard