import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { MediaPath } from "../../Components/MediaPath";
import WriteHelper from "../../Components/WriteHelper";
import Translate, { formatDate, translate } from "../../AppContext/Translate";
import RTEditor from "../../Components/RTEditor";
import UploadProgress from "../../Components/UploadProgress";
import Button from 'react-bootstrap/Button';
import { FiUpload } from 'react-icons/fi';
import rtEditorUploader from "../../Components/RTEditorUploader";

function Review({student, pkgId, courseId, task, review}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [message, setMessage] = useState({text: '', files: []});
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        async function parseReview(comment, path) {
            setMessage(await WriteHelper.parseMessage(comment, path, userId, token));
        }
        var path = new MediaPath(MediaPath.kind_privateTaskReview);
        path.add(student);
        path.add(pkgId);
        path.add(task.post);
        path.add(review ? review.teacher : userId);
        if (review) {
            parseReview(review.comment, path);
        }
    }, [student, pkgId, review, userId, token, task.post]);

    const saveSuccess = () => {
        alert(translate("Зміни успішно збережено", dicLang));
        setProgress(null);
    }

    const saveError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти коментар", dicLang));
        setProgress(null);
    }

    const save = () => {
        var path = new MediaPath(MediaPath.kind_privateTaskReview);
        path.add(student);
        path.add(pkgId);
        path.add(task.post);
        path.add(userId);
        rtEditorUploader([message], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'review/update', params: {userId: userId, token: token, student: student,
                taskId: task.id, pkgId: pkgId, courseId: courseId}}],
            saveSuccess, saveError);
    }

    return <>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <p style={{margin: "0px", fontWeight: "600"}}><Translate>Перевірка</Translate></p>
            {review && <div className="chatUserName">{formatDate(review.reviewDate)}</div>}
        </div>
        <RTEditor item={message} onChange={setMessage}/>
        {progress ? <UploadProgress progress={progress}/> :
        <div className="top8" style={{display: "flex", justifyContent: "flex-end"}}>
            <Button style={{display: "flex", alignItems: "center"}} onClick={save}>
                <Translate>{review ? 'Підтвердити' : 'Надіслати'}</Translate> <FiUpload/>
            </Button>
        </div>}
    </>
}

export default Review