import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { MediaPath } from "../../Components/MediaPath";
import ChatComment from "./ChatComment";
import ChatNormalPost from "./ChatNormalPost";
import ChatTask from "./ChatTask";
import { getAPICall } from "../../Components/APICall";

function ChatPost({post, course, topic, student, allExpanded, refreshData, pageParams, setParams}) {
    const highlightPost = pageParams.courseId === course && pageParams.topicId === topic.day && pageParams.postId !== -1 ?
        pageParams.postId : null;
    const highlightComment = highlightPost !== null && pageParams.commentId.length > 0 ? pageParams.commentId : null;
    if (highlightComment !== null) allExpanded = true;
    const { userId, token, lastNotification } = useContext(AppContext);
    const [reactions, setReactions] = useState([]);
    const [comments, setComments] = useState([]);
    const [expanded, setExpanded] = useState(false);
    
    const changeExpanded = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        setExpanded(allExpanded);
    }, [allExpanded]);

    const reactionsSuccess = (data) => {
        if (data.length === 0 || (data.length > 0 && !("error" in data[0])))
            setReactions(data);
    }
    const commentsSuccess = (data) => {
        if (data.length === 0 || (data.length > 0 && !("error" in data[0]))) {
            setComments(data);
        }
    }

    useEffect(() => {
        if (student.group) {
            getAPICall('reactions/list', {userId: userId, token: encodeURIComponent(token), 
                postId: post["post"], onComment: 0, groupId: student.group}, reactionsSuccess);
            getAPICall('comments/list', {userId: userId, token: encodeURIComponent(token),
                postId: post["post"], onComment: 0, groupId: student.group}, commentsSuccess);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post, userId, token]);

    useEffect(() => {
        if (lastNotification !== null) {
            if (lastNotification["event"] === "reaction") {
                if (lastNotification["parentPost"] === 0 && lastNotification["msgId"] === post["post"] &&
                    lastNotification["parentUser"] === 0 && lastNotification["group"] === student.group) {    
                    getAPICall('reactions/list', {userId: userId, token: encodeURIComponent(token), 
                        postId: post["post"], onComment: 0, groupId: student.group}, reactionsSuccess);
                }
            }
            else if (lastNotification["event"] === "comment") {
                if (lastNotification["parentPost"] === post["post"] && lastNotification["parentUser"] === -1 
                    && lastNotification["group"] === student.group) {
                    getAPICall('comments/list', {userId: userId, token: encodeURIComponent(token), 
                        postId: post["post"], onComment: 0, groupId: student.group}, commentsSuccess);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotification]);

    var path = new MediaPath(MediaPath.kind_privateCoursePost);
    path.add(course);
    path.add(topic.topic);
    path.add(post.post);

    return <>
        {post["taskName"] ?
        <ChatTask post={post} refreshData={refreshData} userId={userId}
            token={token} student={student} setExpanded={setExpanded} reactions={reactions}
            comments={comments} changeExpanded={changeExpanded} highlightPost={highlightPost}
            highlightComment={highlightComment} courseId={course} setParams={setParams}
            pageParams={pageParams} path={path}/> :
        <ChatNormalPost post={post} refreshData={refreshData} userId={userId}
            token={token} student={student} setExpanded={setExpanded} reactions={reactions}
            comments={comments} changeExpanded={changeExpanded} path={path} highlightPost={highlightPost}
            highlightComment={highlightComment} courseId={course}/>
        }
        {expanded && comments.map((comment, index) => (
            <ChatComment key={index} student={student} index={index} comment={comment} postPath={[post["post"]]} 
            last={[index === comments.length - 1]} allExpanded={allExpanded} highlightComment={highlightComment}
            courseId={course} parentId={post["post"]} parentUser={-1}/>
        ))}
    </>
}

export default ChatPost