import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import { endDate } from "../Components/DateFormat";
import Button from "react-bootstrap/Button";
import { formatDate } from "../AppContext/Translate";
import ScheduledCourseContent from "./Content/ScheduledCourseContent";
import { AppContext } from "../AppContext/AppContext";
import BeatLoader from "react-spinners/BeatLoader";
import StaticCourseContent from "./Content/StaticCourseContent";
import ChoosePackage from "./ChoosePackage";

function Group({group, selected, setSelectedGroup}) {
    return <Button size="sm" variant={selected ? "primary" : "secondary"} style={{margin: "4px"}}
        onClick={() => { setSelectedGroup(group.id); }}>
        {formatDate(group.startDate)}<br/>{formatDate(group.endDate)}
    </Button>
}

export function ChooseGroup({courseId, numDays, selectedGroup, setSelectedGroup}) {
    const [groups, setGroups] = useState({active: null, historical: []});

    const updateGroups = (data, currentDay, numDays) => {
        var active = null;
        var historical = [];
        var lastGroupId = null;
        var maxDif = null;
        data.forEach((group) => {
            const groupStartDate = new Date(group.startDate);
            const groupEndDate = endDate(groupStartDate, numDays);
            const groupData = {id: group.id, startDate: groupStartDate, endDate: groupEndDate};
            const dif = (groupEndDate - currentDay) / 86400000;
            if (dif < 0) historical.push(groupData);
            else if (dif < numDays) active = groupData;
            if (lastGroupId === null || maxDif > dif) {
                lastGroupId = group.id;
                maxDif = dif;
            }
        });
        setGroups({active: active, historical: historical});
        if (lastGroupId) setSelectedGroup(lastGroupId);
    }

    useEffect(() => {
        getAPICall('check/today', {}, (data) => {
            if (data && 'today' in data) {
                const currentDay = new Date(data.today);
                currentDay.setHours(0,0,0,0);
                getAPICall('course/groups', {course: courseId}, (data) => {
                    if (data) updateGroups(data, currentDay, numDays);
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId, numDays]);

    return <div style={{display: "flex", flexWrap: "wrap", padding: "8px"}}>
        {groups.active && <Group group={groups.active} selected={selectedGroup === groups.active.id}  setSelectedGroup={setSelectedGroup}/>}
        {groups.historical.length > 0 && groups.historical.map((group, i) => {
            return <Group key={i} group={group} selected={selectedGroup === group.id} setSelectedGroup={setSelectedGroup}/>
        })}
    </div>
}

function TeacherCourseMaterials({course, params, setParams}) {
    const { userId } = useContext(AppContext);
    const [selectedGroup, setSelectedGroup] = useState(params.group >= 0 ? params.group : -1);
    const [selectedPkg, setSelectedPkg] = useState({id: -1, plan: 0});

    useEffect(() => {
        getAPICall('course/packages', {courseId: course.id, order: 0, active: 1}, (data) => {
            if (data) {
                var selPkg = null;
                for (var i = 0; i < data.length; i++) {
                    const pkg = data[i];
                    if (pkg.plan === 1) {
                        selPkg = {id: pkg.id, plan: 1};
                        break;
                    }
                }
                if (!selPkg && data.length > 0)
                    selPkg = {id: data[0].id, plan: 0};
                setSelectedPkg(selPkg);
            }
        });
    }, [course.id]);

    const student = {id: userId, package: selectedPkg.id, plan: selectedPkg.plan, options: "",
        group: selectedGroup, student: false};

    return <>
        <ChooseGroup courseId={course.id} numDays={course.numDays} selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}/>
        {student.package >= 0 ? (
            student.plan === 0 ? 
            <StaticCourseContent course={course} student={student} params={params} setParams={setParams}/>
            : <ScheduledCourseContent course={course} student={student} params={params} setParams={setParams}/>
        ): <BeatLoader/>}
    </>    
}

function StudentCourseMaterials({course, student, params, setParams, selectedPackage, setSelectedPackage}) {
    const { userId } = useContext(AppContext);
    
    var studentData = null;
    for (var i = 0; i < student.length; i++) {
        if (student[i].package === selectedPackage) {
            studentData = {id: userId, package: selectedPackage, plan: student[i].plan, options: student[i].options,
                group: student[i].classgroup, student: true};
            break;
        }
    }

    return <>
        <ChoosePackage student={student} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage}/>
        {studentData && (
            studentData.plan === 0 ? 
                <StaticCourseContent course={course} student={studentData} params={params} setParams={setParams}/>
                : <ScheduledCourseContent course={course} student={studentData} params={params} setParams={setParams}/>
        )}
    </>
}

function CourseMaterials({course, student, teacher, params, setParams, selectedPackage, setSelectedPackage}) {
    return teacher ? <TeacherCourseMaterials course={course} params={params} setParams={setParams}/>
        : <StudentCourseMaterials course={course} student={student} params={params} setParams={setParams}
            selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage}/>
}

export default CourseMaterials