import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import PlanItem from "../Course/PlanItem";
import Translate, { translate, translateWithNumber } from "../AppContext/Translate";
import PostMessage from "../Components/PostMessage";
import { RiGroup2Fill } from "react-icons/ri";
import { HiOutlineEmojiSad } from "react-icons/hi";
import dateFormat, { endDateFormat } from "../Components/DateFormat";
import MagButton from "../Components/MagButton";

function GiftPackage({index, data, options, numDays, setPkgToPurchase}) {
    const { dicLang } = useContext(AppContext);
    const [startDates, setStartDates] = useState(null);

    useEffect(() => {
        if (data.plan === 1) {
            getAPICall('group/start', { courseId: data.course }, (datag) => {
                if (datag) {
                    var dates = [];
                    for (var i = 0; i < datag.length; i++) {
                        const row = datag[i];
                        if (row.elapsedDays < row.numDays - 1) {
                            dates.unshift(row);
                        } else break;
                    }
                    setStartDates(dates);
                }
            });
        }
    }, [data.course, data.plan]);

    const months = Math.round(data.daysToExpire / 3) / 10;
    const expiration = data.daysToExpire >= 365000 ? translate("Постійний доступ до всіх уроків і матеріалів курсу", dicLang)
        : translateWithNumber("Доступ до всіх уроків і матеріалів курсу протягом * місяців", months, dicLang);

    return <div style={{ width: "360px", maxWidth: "100%", margin: "16px" }}>
        <div className="center" style={{ zIndex: 100 }}>
            <img alt="" src="/bkg/package.png" style={{ width: "64px" }} />
        </div>
        <div style={{ marginTop: "-28px", color: "black" }}>
            <img alt="" src="/bkg/decor/top.png" style={{ width: "100%", display: "block" }} />
            <div style={{ background: "white" }} className="pad12">
                <p style={{ paddingTop: "24px", paddingBottom: "16px" }} className="bold center xlarge">{data.title}</p>
                <PlanItem text={expiration} desc={data.daysToExpire >= 365000 ? null :
                    translateWithNumber("Гарантований доступ * днів", data.daysToExpire, dicLang)} />
                <PlanItem text={data.plan === 0 ? translate("Навчайтеся у своєму темпі", dicLang) :
                    translateWithNumber("Програма курсу розрахована на * день", numDays, dicLang)} />
                {data.plan === 1 && <PlanItem text="Творча спільнота" translateText={true} translateDesc={true}
                    desc="Доступ до чату для обговорення матеріалів курсу з однокурсниками та викладачами" />}
                {data.options.includes('Y') && <PlanItem text="Отримуйте відгуки від викладачів щодо ваших завдань" translateText={true} />}
                {data.options.includes('Z') && <PlanItem text="Підсумковий проект курсу" translateText={true} />}
                {data.options.includes('Z') ? <PlanItem text="Диплом" translateText={true} />
                : <PlanItem text="Сертифікат" translateText={true} />}
                {data.options.split('').map((c, index) => (
                    c !== 'Y' && c !== 'Z' && c in options &&
                    <PlanItem key={index} text={options[c].title}
                        desc={options[c].description && options[c].description.length > 0 ?
                            <PostMessage msg={options[c].description} sep={false} open={false} textStyle="smallTextPost" /> : null} />
                ))}
                <p style={{
                    textAlign: "center", fontSize: "large", fontWeight: "600", fontFamily: "Mak",
                    marginTop: "16px"
                }}>{data.price} ГРН.</p>
                {data.plan === 1 && startDates && <>
                    <hr />
                    {startDates.length < 1 ?  <p><HiOutlineEmojiSad size="22"/>{' '}<Translate>Нові групи не плануються</Translate>.<br/>
                        <span style={{fontStyle: "italic"}}>
                        <Translate>Зверніться до викладачів, якщо ви зацікавлені у відкритті групи найближчим часом</Translate>.</span>
                    </p> : <>
                        <p style={{ fontStyle: "italic" }}><RiGroup2Fill size={20} style={{ marginRight: "6px" }}
                            color="darkgray" /><Translate>Групи, що заплановані</Translate>:</p>
                        <ul>
                        {startDates.map((date, k) => {
                            const startDate = new Date(date.startDate);
                            const label = translate('з', dicLang)  + ' ' + dateFormat(startDate) +
                                ' ' + translate('по', dicLang) + ' ' + endDateFormat(startDate, date.numDays);
                            return <li key={k}>{label}</li>
                        })}
                        </ul>
                    </>}
                </>}
                {(data.plan === 0 || (startDates && startDates.length > 0)) &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                    <MagButton text="ПОДАРУВАТИ" icon="/icons/present.png"
                        clickEff={{customFnc: () => { setPkgToPurchase(data); }}}/>
                </div>}
            </div>
        </div>
    </div>
}

export default GiftPackage