import { useParams } from "react-router-dom";
import Footer from "../Components/Footer"
import MagBanner from "../Components/MagBanner"
import Menu from "../Menu/Menu"
import Translate from "../AppContext/Translate";
import HomeButton from "../Components/HomeButton";
import CourseList from "../Lists/CourseList";
import { useContext } from "react";
import { AppContext } from "../AppContext/AppContext";
import Button from "react-bootstrap/Button";

function GiftCard() {
    const { userId } = useContext(AppContext);
    var design = useParams()["id"];
    if (!design) design = 1;

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Подаруйте Творчість" icon="/icons/gift.png" bkgColor="#fef5de"/>
        <div style={{padding: "24px"}}>
            {userId > 0 ? <>
                <p className="bold large bot24"><Translate>Виберіть курс, який хочете подарувати</Translate>:</p>
                <CourseList cardKind="gift" linkParams={{design: design}}
                    filter={{show: true, school: 0, cat: 0, lang: ''}}/>
            </> : <>
                <p className="bot16 top18"><Translate>Ви повинні створити обліковий запис, аби мати можливість робити покупки на платформі</Translate>.</p>
                <Button onClick={() => {
                    document.getElementById("menuCreateAccountButton").click();
                }}><Translate>Створити акаунт</Translate></Button>
            </>}
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}
export default GiftCard