function ShowCourseGift({course, courseTitle, pkgName}) {
    const pic = "/courses/cover" + course + ".jpg";
    return <div className="top24 bot24" style={{display: "flex", flexWrap: "wrap", gap: "16px"}}>
        <img alt={courseTitle} src={pic} style={{width: "100%", maxWidth: "320px"}}/>
        <div>
            <p className="fkenyan bold xlarge">{courseTitle}</p>
            <p className="bold large top12">{pkgName}</p>
        </div>
    </div>
}

export default ShowCourseGift
