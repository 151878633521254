import { useContext, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import CabCourseSelection from "../Courses/CabCourseSelection";
import CabTitle from "../CabTitle";
import CabStudentsIndOptions from "./CabStudentsIndOptions";
import CabHomeButton from "../CabHomeButton";
import CabStudentsSharedOptions from "./CabStudentsSharedOptions";
import Approvals from "./Approvals";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import ModalityCard from "./ModalityCard";
import { useNavigate } from "react-router-dom";
import MagBanner from "../../Components/MagBanner";

function CabStudents() {
    const { userId } = useContext(AppContext);
    const [coursesData, setCoursesData] = useState(null);
    const [expanded, setExpanded] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [pkgByType, setPkgByType] = useState({stat: 0, dyn: 0});
    const navigate = useNavigate(); 

    const selectCourse = (id) => {
        setSelectedCourse(id);
        setExpanded(false);
        getAPICall('course/packages', {courseId: id, order: 0, active: 0}, (data) => {
            if (data) {
                var stat = 0, dyn = 0;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].plan === 0) stat++;
                    else dyn++;
                }
                setPkgByType({stat: stat, dyn: dyn});
            }
        });
    }

    const onPlanSelect = (plan) => {
        navigate("/cabinet/plan/" + selectedCourse + "-" + plan);
    }

    return userId < 0 ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <MagBanner title="Студенти" icon="/icons/students.png"/>
        <div style={{padding: "10px"}}>
            <CabTitle/>
            <div style={{height: "12px"}}/>
            <CabStudentsIndOptions/>
            <CabStudentsSharedOptions/>
            <Approvals/>
            <div style={{height: "12px"}}/>
            <CabCourseSelection coursesData={coursesData} setCoursesData={setCoursesData}
                expanded={expanded} setExpanded={setExpanded} selectCourse={selectCourse}/>
            {selectedCourse !== null && <>
                <h3 style={{marginTop: "8px", color: "cadetblue"}}>{coursesData[selectedCourse].title}</h3>
                {pkgByType.stat === 0 && pkgByType.dyn === 0 ?
                    <p><Translate>Немає пакетів</Translate></p> :
                <>
                    <p><Translate>Виберіть модальність</Translate>:</p>
                    <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                        {pkgByType.stat > 0 && <ModalityCard icon="static" title="Статичний курс"
                            onSelect={() => { onPlanSelect(0); }}/>}
                        {pkgByType.dyn > 0 && <ModalityCard icon="chat" title="Групові заняття з доступом до чату"
                            onSelect={() => { onPlanSelect(1); }}/>}
                    </div>
                </>}
            </>}
            <CabHomeButton/>
        </div>
        <Footer/>
    </div>
}

export default CabStudents