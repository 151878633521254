import { Suspense, lazy } from "react";
import BeatLoader from 'react-spinners/BeatLoader';
import GeneralCourseInfo from "./GeneralCourseInfo";
import CourseMenu from "./CourseMenu";

function CustomInfo({courseId, student, goToPlans}) {
    const Loading = () => <BeatLoader size={28} color="#0d6efd" />;

    const componentMap = {
        1: lazy(() => import('./Custom/CourseDesign1')),
        2: lazy(() => import('./Custom/CourseDesign3')),
        3: lazy(() => import('./Custom/CourseDesign4')),
        4: lazy(() => import('./Custom/CourseDesign5')),
        6: lazy(() => import('./Custom/CourseDesign6')),
        7: lazy(() => import('./Custom/CourseDesign2')),
    };

    const SelectedComponent = componentMap[courseId];

    return SelectedComponent ? (
        <Suspense fallback={<Loading />}>
            <SelectedComponent courseId={courseId} student={student} goToPlans={goToPlans} />
        </Suspense>
    ) : null;
}

function CourseInfo({info, student, showMenu=false, goToPlans=false}) {
    const custom = [1, 2, 3, 4, 6, 7].includes(info.id);
    return <>
        {showMenu && <CourseMenu courseId={info.id}/>}
        {!custom ? <GeneralCourseInfo courseId={info.id} student={student} info={info} goToPlans={goToPlans}/> :
        <CustomInfo courseId={info.id} student={student} goToPlans={goToPlans}/>}
    </>
}

export default CourseInfo