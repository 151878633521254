import Translate from "../AppContext/Translate";
import Footer from "../Components/Footer";
import HomeButton from "../Components/HomeButton";
import Menu from "../Menu/Menu";

function ErrorPage() {
    return <div className='mainContainer'>
        <Menu/>
        <div className="pad24 center">
            <p><img alt="Oops!" src="/bkg/oops.png" style={{width: "100%", maxWidth: "400px", height: "auto"}}/></p>
            <h2 className="top24"><Translate>СТОРІНКА НЕ ЗНАЙДЕНА</Translate></h2>
            <p className="top24 bot24"><Translate>Можливо, сторінка, яку ви шукаєте, змінила адресу або тимчасово недоступна. Якщо ви вважаєте, що це помилка, будь ласка, напишіть до нашого центру підтримки</Translate>: <a href="mailto:support@magistrika.com">support@magistrika.com</a></p>
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default ErrorPage;