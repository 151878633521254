import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import { AppContext } from "../AppContext/AppContext";
import { Link } from "react-router-dom";

function UserRank({user}) {
    const { userId } = useContext(AppContext);
    const [rank, setRank] = useState(null);

    useEffect(() => {
        getAPICall('user/rank', {user: user}, (data) => {
            if (data) {
                if (data.length === 0) setRank(0);
                else setRank(data[0].rank);
            }
        });
    }, [user]);

    const img = <img src={"/icons/rank" + rank + ".png"} alt="" width="64px" style={{marginLeft: "6px"}}/>; 
    const userInt = typeof user === 'string' ? parseInt(user, 10) : user;

    return <p className="fkenyan">
        <Translate>Рівень</Translate> {rank + 1}
        {userInt === userId ? <Link to="/user/rewards">{img}</Link> : img }
    </p>
}

export default UserRank