import Form from 'react-bootstrap/Form';
import Translate from '../AppContext/Translate';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useRef, useState } from 'react';
import { FaFilePdf } from "react-icons/fa6";
import Button from "react-bootstrap/Button"
import { useWindowSize } from '../AppContext/WindowSize';
import { PiArrowUpBold } from "react-icons/pi";
import { Link } from 'react-router-dom';

function SchoolTerms({agree, setAgree, readOnly}) {
    const [showAgreement, setShowAgreement] = useState(false);
    const [width,] = useWindowSize();
    const [showUp, setShowUp] = useState(false);
    const modalRef = useRef(null);

    const handleDownload = () => {
        const pdfUrl = '/docs/schoolTerms.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'schoolTerms.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (modalRef.current.scrollTop > 200) {
                setShowUp(true);
            } else {
                setShowUp(false);
            }
        };
    
        const currentModal = modalRef.current;
        if (currentModal) {
            currentModal.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (currentModal) {
                currentModal.removeEventListener('scroll', handleScroll);
            }
        };
    }, [showAgreement]);

    return <>
        <Modal show={showAgreement} fullscreen={true} onHide={() => setShowAgreement(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>
                        <p className="fmak large bold">ДОГОВІР ПРО НАДАННЯ ПОСЛУГ З ПУБЛІКАЦІЇ ТА РОЗПОВСЮДЖЕННЯ ОСВІТНІХ МАТЕРІАЛІВ НА ПЛАТФОРМІ MAGISTRIKA</p>
                        <div className="top12 bot16 fkenyan">
                            <Button variant="light" style={{color: "#595e69"}} onClick={handleDownload}>
                                <FaFilePdf style={{marginRight: "6px"}}/>PDF версія
                            </Button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#e8e8e8", padding: "0px"}} ref={modalRef}>
                {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                    style={{left: "calc(50% + 360px + 48px)", top: "184px"}} onClick={handleDownload}>
                    <FaFilePdf size="24" style={{transform: "translateX(2px)"}}/>
                </div>}
                {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                    style={{left: "calc(50% - 360px - 120px)", bottom: "84px"}} onClick={() => {
                        modalRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}><PiArrowUpBold size="24"/>
                </div>}
                <div className="terms-div">
                    <p className="pterms">Цей договір (надалі "Договір") укладається між платформою magistrika.com (надалі "Платформа"), та фізичною або юридичною особою (надалі "Директор Школи"), яка створює та публікує навчальні курси та інші освітні матеріали на Платформі.</p>
                    <ol>
                        <li><strong>Предмет Договору</strong></li>
                        <ol>
                            <li>Директор Школи зобов&rsquo;язується створити навчальну школу ("Школа") на Платформі та публікувати навчальні курси ("Курси") і/або інші продукти в рамках цієї Школи.</li>
                            <li>Платформа надає Директору Школи можливість продавати Курси та інші товари через створену Школу на умовах, визначених цим Договором.</li>
                            <li>Перед публікацією всі Курси проходять обов'язкову модерацію, яка триває від 5 до 10 робочих днів. Платформа залишає за собою право відхилити або вимагати коригування матеріалів, якщо вони не відповідають вимогам Платформи.</li>
                        </ol>
                    </ol>
                    <ol start="2">
                        <li><strong>Винагорода та обрахунки</strong></li>
                        <ol>
                            <li>Платформа утримує 35% від кінцевої вартості кожного проданого Курсу, товару або іншого продукту з магазину, що функціонує на Платформі.</li>
                            <li>Всі виплати здійснюються Директору Школи за запитом Директора, але не частіше одного разу на місяць з 20 по 30 число на рахунок, зазначений Директором Школи. Запит на виплату має здійснюватися у період з 1 по 20 число місяця. Виплати, на які поступив запит з 21 числа по останній день місяця, здійснюватимуться наступного місяця з 20 по 30 число.</li>
                            <li>У випадку, якщо на курсі працює більше одного викладача, питання про розподіл коштів між викладачами врегульовується окремо та офіційно поза Платформою. Всі виплати викладачам здійснюються безпосередньо Директором Школи.</li>
                            <li>Директор Школи та викладачі отримують щомісячний звіт про зароблені кошти за курс, доступний в особистому кабінеті.</li>
                        </ol>
                    </ol>
                    <ol start="3">
                        <li><strong>Магазин товарів</strong></li>
                        <ol>
                            <li>Директор Школи має право відкрити магазин на Платформі для продажу товарів, що не є курсами. До таких товарів можуть належати, але не обмежуються: навчальні посібники, методичні матеріали, електронні книги, тематичні сувеніри, брендовані товари Школи.</li>
                            <li>Усі продукти повинні бути навчальними або тематично пов'язаними з одним із курсів, опублікованих у школі. Платформа залишає за собою право вилучати товари з магазину, які не відповідають цим вимогам.</li>
                            <li><strong>Заборонено продаж наступних категорій товарів:</strong></li>
                            <ul>
                                <li className="terms-ilist">Тютюнові вироби;</li>
                                <li className="terms-ilist">Алкогольні напої;</li>
                                <li className="terms-ilist">Зброя та будь-які її компоненти;</li>
                                <li className="terms-ilist">Порнографічні матеріали та інші товари, що мають порнографічний характер;</li>
                                <li className="terms-ilist">Наркотичні речовини та препарати;</li>
                                <li className="terms-ilist">Товари, що порушують права інтелектуальної власності третіх осіб;</li>
                                <li className="terms-ilist">Товари, що порушують чинне законодавство України або морально-етичні норми.</li>
                            </ul>
                        </ol>
                    </ol>
                    <ol start="4">
                        <li><strong>Акції та знижки</strong></li>
                        <ol>
                            <li>Платформа залишає за собою право самостійно організовувати та проводити акції, надавати знижки та пропонувати інші спеціальні умови для покупців.</li>
                            <li>Всі витрати, пов'язані з проведенням акцій та наданням знижок, Платформа бере на себе.</li>
                        </ol>
                    </ol>
                    <ol start="5">
                        <li><strong>Права на матеріали</strong></li>
                        <ol>
                            <li>Директор Школи надає Платформі права на розповсюдження, публікацію, рекламування та продаж створених ним та його викладачами матеріалів, включаючи Курси та інші продукти, на необмежений термін або до моменту припинення Договору.</li>
                            <li>Директор Школи та викладачі не мають права ділитися або виставляти матеріали, надані Платформі, на інших ресурсах або надавати доступ до них третім особам без попередньої письмової згоди Платформи.</li>
                            <li>Платформа залишає за собою право припинити розповсюдження будь-яких матеріалів, які, на її думку, не відповідають політиці Платформи, чинному законодавству або морально-етичним нормам.</li>
                        </ol>
                    </ol>
                    <ol start="6">
                        <li><strong>Додаткові послуги</strong></li>
                        <ol>
                            <li>Директор Школи та викладачі мають можливість скористатися послугами фахівців Платформи за додаткову оплату. Серед доступних послуг:</li>
                            <ul>
                                <li className="terms-ilist"><strong>Інформаційне наповнення сторінки курсу:</strong> Створення та структуризація текстового контенту для сторінки курсу, включаючи опис, програму, цільову аудиторію, та ключові переваги навчання.</li>
                                <li className="terms-ilist"><strong>Персоналізований дизайн сторінки курсу:</strong> Розробка унікального дизайну сторінки курсу, що включає графічні елементи, кольорові схеми та візуальне оформлення, адаптовані під бренд і тематику курсу.</li>
                                <li className="terms-ilist"><strong>Прошивка від програміста:</strong> Технічне налаштування функціональних елементів сторінки курсу, включаючи інтеграцію з навчальною платформою, створення та оптимізацію форм, кнопок і модулів.</li>
                                <li className="terms-ilist"><strong>Монтаж і дизайн відео:</strong> Професійна обробка відеоматеріалів для курсу, включаючи монтаж, додавання титрів, спецефектів, анімацій та графічних елементів, що підвищують якість відеоконтенту.</li>
                                <li className="terms-ilist"><strong>Дизайн креативів і заставок для курсів і продуктів:</strong> Створення візуальних елементів, таких як обкладинки, банери, заставки і промо-матеріали, які підкреслюють унікальність курсу та сприяють залученню студентів.</li>
                                <li className="terms-ilist"><strong>Презентаційне відео для курсу:</strong> Розробка короткого рекламного відео, яке представляє курс, його ключові переваги та особливості, сприяючи підвищенню зацікавленості потенційних студентів.</li>
                                <li className="terms-ilist"><strong>Редакція тексту курсу і Школи:</strong> Вичитка та редагування всіх текстових матеріалів курсу та школи для забезпечення їх граматичної та стилістичної правильності, ясності та відповідності стандартам Платформи.</li>
                                <li className="terms-ilist"><strong>Індивідуальний супровід створення курсу:</strong> Персональна підтримка та консультації на всіх етапах розробки курсу, від планування і створення контенту до його публікації та просування на Платформі.</li>
                                <li className="terms-ilist"><strong>Таргетолог:</strong> Налаштування та управління рекламними кампаніями в соціальних мережах та інших онлайн-каналах для просування курсу та залучення цільової аудиторії.</li>
                                <li className="terms-ilist"><strong>Ілюстратор:</strong> Створення унікальних ілюстрацій та графічних матеріалів, що додають візуальної привабливості курсу та сприяють кращому засвоєнню матеріалу студентами.</li>
                            </ul>
                            <li>Детальну інформацію про ціни, види робіт, а також приклади робіт можна знайти у вкладці <Link to="/services">Послуги Платформи</Link>.</li>
                        </ol>
                    </ol>
                    <ol start="7">
                        <li><strong>Припинення Договору</strong></li>
                        <ol>
                            <li>Договір може бути припинений будь-якою зі сторін шляхом надання письмового повідомлення за 30 днів до бажаної дати припинення.</li>
                            <li>Платформа має право припинити Договір в односторонньому порядку без попереднього повідомлення у випадку порушення Директором Школи або викладачами умов цього Договору.</li>
                        </ol>
                    </ol>
                    <ol start="8">
                        <li><strong>Інші умови</strong></li>
                        <ol>
                            <li>Директор Школи погоджується з умовами цього Договору, ставлячи відповідну позначку на сторінці реєстрації або публікації Курсу на Платформі.</li>
                            <li>Директор Школи зобов&rsquo;язується укласти окремий офіційний договір з кожним викладачем, що працює на Курсах Школи.</li>
                            <li>Всі зміни та доповнення до цього Договору дійсні лише за умови їх оформлення у письмовому вигляді та підписання обома сторонами.</li>
                            <li>Усі спори, що виникають у зв'язку з виконанням цього Договору, вирішуються шляхом переговорів, а у разі недосягнення згоди &ndash; у судовому порядку відповідно до законодавства України.</li>
                        </ol>
                    </ol>
                </div>
            </Modal.Body>
        </Modal>
        <div className="top8 italic">
            <Form.Check type="checkbox" id="agreement" disabled={readOnly} label={
                <span>
                    <Translate>Я прочитав/-ла і приймаю</Translate>{" "}
                    <span className='blackTextHover' style={{textDecoration: "underline"}} onClick={() => {
                        setShowAgreement(true);
                    }}><Translate>умови договору</Translate></span>.
                </span>}
                style={{marginLeft: "6px"}} checked={agree} onChange={() => { setAgree(!agree); }} />
        </div>
    </>
}

export default SchoolTerms